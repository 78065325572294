import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import leftarrow from "../../../../assets/image/qrcode/arrow-left.svg";
import InputBox from "../../../../Component/Merchant/Forms/InputBox";
import RadioBtn from "../../../../Component/Merchant/RadioBtn";
import qrcode from "../../../../assets/image/qrcode/qrcode.svg";
import star from "../../../../assets/image/qrcode/star.svg";
import play from "../../../../assets/image/qrcode/play.svg";
import link from "../../../../assets/image/qrcode/link.svg";
import copy from "../../../../assets/image/qrcode/copy.svg";
import SubHead from "../../../../Component/Merchant/SubHead";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import Dropdownpink from "../../../../Component/Merchant/Forms/Dropdownpink";
import circlecheck from "../../../../assets/image/qrcode/circle-check.svg";
import line from "../../../../assets/image/qrcode/Line.svg";
import linefull from "../../../../assets/image/qrcode/Line-Full.svg";
import phone from "../../../../assets/image/qrcode/Screenshot.svg";
function QRType() {
    return (
        <>
            <SubHead head="QR code" first="Home" second="QR Code" third="All Codes" fourth="Create QR Code"/> 
            <br/>
            <Row>
                <Col>
                    <div className="left-arrow">
                        <h3 className="color-black DMSans-Regular f-18 fw-400"><img src={leftarrow} alt="leftarrow"/><Link to="/qrcode">Create New QR Code</Link></h3>    
                        
                    </div>
                </Col>
                <Col>
                    <div className="d-flex justify-content-end">
                        <button className="btn-download bgcolor-cerise">
                          <h1 className="DMSans-Regular f-18 fw-600 color-white">Watch Demo</h1>  
                        </button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="Qr-Type" md={12} lg={7}>
                    <Row >
                        <Col>
                            <h3 className="Inter-Regular f-16 fw-500 text-center color-emperor">Steps{' '}
                            <span className="Inter-Regular f-16 fw-700 text-center color-black">1</span>{' '}
                            <span className="Inter-Regular f-16 fw-500 text-center color-emperor">of 3</span></h3>                     
                        </Col>
                    </Row>
                    <Row className="check-layout">
                        <Col className="mb-3">
                            <img src={circlecheck} alt=""></img> 
                            <h3 className="Inter-Regular f-16 fw-500 color-emperor">Details</h3>
                        </Col>
                        <Col className="mb-3">
                            <img src={line} alt=""></img> 
                        </Col>
                        <Col className="mb-3">
                            <img src={circlecheck} alt=""></img> 
                            <h3 className="Inter-Regular f-16 fw-500 color-emperor">Contents</h3>
                        </Col>
                        <Col className="mb-3">
                            <img src={line} alt=""></img> 
                        </Col>
                        <Col className="mb-3">
                            <img src={circlecheck} alt=""></img> 
                            <h3 className="Inter-Regular f-16 fw-500 color-emperor">Design</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} lg={6}>
                            <InputBox type="text" label="QR Code Name" placeholder="Test Code" key_name="sample"/>   
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                       <img src={linefull} alt=""></img>    
                    </Row>
                    <br/>
                    <Row className="d-inline-flex">
                        <h3 className="Inter-Regular f-14 fw-400">Select QR Code Type</h3>
                        <Col>
                            {/* <input type="radio" value="Static QR" name="qrtype" className="radio"/> Static QR */}
                              <RadioBtn/>Static QR
                        </Col>
                        <Col>
                            {/* <input type="radio" value="Dynamic QR" name="qrtype" className="radio"/> Dynamic QR */}
                            <RadioBtn/>Dynamic QR
                        </Col>                        
                    </Row>
                    <br/>
                    <Row>
                        <h3 className="Inter-Regular f-14 fw-400">. We recommend creating a dynamic QR code if you want to track performance and edit data even after printing.</h3>
                    </Row>
                    <br/>
                    <Row>
                       <img src={linefull} alt=""></img>    
                    </Row>
                    <br/>
                    <Row className="d-inline-flex">
                        <h3 className="Inter-Regular f-14 fw-400">Select Video Mode</h3>
                        <Col>
                            {/* <input type="radio" value="Video Mode" name="qrtype" className="radio"/> Portrait */}
                            <RadioBtn/>Portrait
                        </Col>
                        <Col>
                            {/* <input type="radio" value="Video Mode" name="qrtype" className="radio"/> Landscape */}
                            <RadioBtn/>Landscape
                        </Col>                        
                    </Row>
                    <br/>
                    <Row>
                       <img src={linefull} alt=""></img>    
                    </Row>
                    <br/>
                    <Row className="d-inline-flex">
                        <h3 className="Inter-Regular f-14 fw-400">Add your Customized Parameter</h3>
                        <Col>
                            <input type="text" placeholder="Language" className="t-box"/> 
                        </Col>
                        <Col>
                            <input type="text" placeholder="Important Section" className="t-box"/>
                        </Col>                        
                        <Col>
                            <button className=" btn-export bgcolor-white ">
                                <h3 className="color-black DMSans-Regular f-18 fw-600">
                                    Add Custom parameter
                                </h3>
                            </button>
                        </Col>
                    </Row>
                    <br/><br/>
                    <Row>
                       <img src={linefull} alt=""></img>    
                    </Row>
                    <br/>
                    <div className="d-flex justify-content-start">
                        <button className="btn-download bgcolor-cerise">
                          <h1 className="DMSans-Regular f-18 fw-600 color-white">Next</h1>  
                        </button>
                    </div>
                </Col>
                <Col>
                    <div>
                        <img src={phone} alt=""></img>
                    </div>
                    <br/>
                    <div className="d-flex justify-content-between">
                        <button className="btn-download bgcolor-white">
                          <h1 className="DMSans-Regular f-18 fw-600 color-black">Preview Page View</h1>  
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    );
}
export default QRType;