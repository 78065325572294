import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import leftarrow from "../../../../assets/image/qrcode/arrow-left.svg";
import InputBox from "../../../../Component/Merchant/Forms/InputBox";
import TextArea from "../../../../Component/Merchant/Forms/TextArea";
import qrcode from "../../../../assets/image/qrcode/qrcode.svg";
import star from "../../../../assets/image/qrcode/star.svg";
import play from "../../../../assets/image/qrcode/play.svg";
import link from "../../../../assets/image/qrcode/link.svg";
import copy from "../../../../assets/image/qrcode/copy.svg";
import SubHead from "../../../../Component/Merchant/SubHead";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import Dropdownpink from "../../../../Component/Merchant/Forms/Dropdownpink";
import circlecheck from "../../../../assets/image/qrcode/circle-check.svg";
import circlecheckfull from "../../../../assets/image/qrcode/circle-checkfull.svg";
import line from "../../../../assets/image/qrcode/Line.svg";
import linefull from "../../../../assets/image/qrcode/Line-Full.svg";
import phone from "../../../../assets/image/qrcode/Screenshot 1.svg";
import radio from "../../../../assets/image/qrcode/radio.svg";
import appointment from "../../../../assets/image/qrcode/appointments.svg";
import event from "../../../../assets/image/qrcode/event.svg";
import form from "../../../../assets/image/qrcode/file.svg";
import location from "../../../../assets/image/qrcode/pin.svg";
import feedback from "../../../../assets/image/qrcode/feedback.svg";
import gamification from "../../../../assets/image/qrcode/gamification.svg";
import qrsmall from "../../../../assets/image/qrcode/qr-code-small.svg";
import up from "../../../../assets/image/qrcode/up.svg";
function Facebook() {
    return (
        <>
            <SubHead head="QR code" first="Home" second="QR Code" third="All Codes" fourth="Create QR Code"/> 
            <br/>
            <Row>
                <Col>
                    <div className="left-arrow">
                    <h3 className="color-black DMSans-Regular f-18 fw-400"><img src={leftarrow} alt="leftarrow"/><Link to="/qrcode">Create New QR Code</Link></h3>
                    </div>
                </Col>
                <Col>
                    <div className="d-flex justify-content-end">
                        <button className="btn-download bgcolor-cerise">
                          <h1 className="DMSans-Regular f-18 fw-600 color-white">Watch Demo</h1>  
                        </button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="Qr-Type" md={12} lg={7}>
                    <Row >
                        <Col>
                            <h3 className="Inter-Regular f-16 fw-500 text-center color-emperor">Steps{' '}
                            <span className="Inter-Regular f-16 fw-700 text-center color-black">2</span>{' '}
                            <span className="Inter-Regular f-16 fw-500 text-center color-emperor">of 3</span></h3>                     
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3 check-layout">
                        <Col className="mb-3">
                            <img src={circlecheckfull} alt=""></img> 
                            <h3 className="Inter-Regular f-16 fw-500 color-emperor">Details</h3>
                        </Col>
                        <Col className="mb-3">
                            <img src={line} alt=""></img> 
                        </Col>
                        <Col className="mb-3">
                            <img src={circlecheck} alt=""></img> 
                            <h3 className="Inter-Regular f-16 fw-500 color-emperor">Contents</h3>
                        </Col>
                        <Col className="mb-3">
                            <img src={line} alt=""></img> 
                        </Col>
                        <Col className="mb-3">
                            <img src={circlecheck} alt=""></img> 
                            <h3 className="Inter-Regular f-16 fw-500 color-emperor">Design</h3>
                        </Col>
                    </Row>
                    <Row>
                        <h3 className="Inter-Regular f-16 fw-600">Template design<span className="d-flex justify-content-end"><img src={up} alt=""/></span></h3>
                        <Col md={6} lg={6}>
                            <InputBox type="text" label="Header Content" placeholder="Sample video Test" key_name="sample"/>   
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                       <img src={linefull} alt=""></img>    
                    </Row>
                    <br/>
                    <Row>
                        <Col md={6} lg={6}>
                            <TextArea  type="text" label="Description"  placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt." key_name="sample"/>
                        </Col>                     
                    </Row>
                    <br/>
                    <Row>
                       <img src={linefull} alt=""></img>    
                    </Row>
                    <br/>
                    <Row>
                        <h3 className="Inter-Regular f-14 fw-400">CTA option</h3>
                    </Row>
                    <ul className="d-inline-flex">
                        <li className="d-inline-flex justify-content-between px-1">
                            <h3 className="DMSans-Regular f-18 fw-600 color-black cta-option"><img src={radio} alt=""/>{' '}Social media</h3>
                        </li>
                        <li className="d-inline-flex justify-content-between px-3">
                            <h3 className="DMSans-Regular f-18 fw-400 color-emperor"><img src={appointment} alt=""/>{' '}Appointments</h3>
                        </li>
                        <li className="d-inline-flex justify-content-between px-3">
                            <h3 className="DMSans-Regular f-18 fw-400 color-emperor"><img src={event} alt=""/>{' '}Event</h3>
                        </li>
                        <li className="d-inline-flex justify-content-between px-3">
                            <h3 className="DMSans-Regular f-18 fw-400 color-emperor"><img src={form} alt=""/>{' '}Forms</h3>
                        </li>
                        <li className="d-inline-flex justify-content-between px-3">
                            <h3 className="DMSans-Regular f-18 fw-400 color-emperor"><img src={location} alt=""/>{' '}Location</h3>
                        </li>                      
                    </ul>
                    <br/>
                    <ul className="d-inline-flex">
                        <li className="d-inline-flex justify-content-between px-1">
                            <h3 className="DMSans-Regular f-18 fw-400 color-emperor"><img src={feedback} alt=""/>{' '}Feedback Survey</h3>
                        </li>
                        <li className="d-inline-flex justify-content-between px-3">
                            <h3 className="DMSans-Regular f-18 fw-400 color-emperor"><img src={gamification} alt=""/>{' '}Gamification</h3>
                        </li>
                    </ul>
                    <br/>
                    <Row>
                       <img src={linefull} alt=""></img>    
                    </Row>
                    <Row className="d-inline-flex">
                    <Col className="d-flex justify-content-start">
                        <button className="btn-download bgcolor-white">
                          <h1 className="DMSans-Regular f-18 fw-600 color-blak">Back</h1>  
                        </button>
                    </Col>
                    <Col className="d-flex justify-content-between">
                        <button className="btn-download bgcolor-cerise">
                          <h1 className="DMSans-Regular f-18 fw-600 color-white">Next</h1>  
                        </button>
                    </Col>
                    </Row>
                </Col>
                <Col>
                    <div>
                        <img src={phone} alt=""></img>
                    </div>
                    <br/>
                    <div className="d-flex justify-content-between">
                        <button className="btn-download bgcolor-white">
                          <h1 className="DMSans-Regular f-18 fw-600 color-black">Preview Page View{' '}<span><img src={qrsmall} alt=""/></span></h1>  
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    );
}
export default Facebook;