import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
function Dropdown(props) {
  const { list, value, name, dummy, key_name, onhandlechange } = props;
  return (
    <>
      <FormControl sx={{ marginRight: "10px"}} size="small">
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          name={key_name}
          defaultValue={0}
          value={props[key_name] || 0}
          onChange={onhandlechange}
          style={{ backgroundColor: "#D235C3", color:"#ffff", borderRadius: "24px" }}
          IconComponent={ExpandMoreOutlined}
        >
          <MenuItem value={0}>{dummy}</MenuItem>
          {list?.map((a, i) => {
            return (
              <MenuItem key={i} value={a[value]}>
                {a[name]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}
export default React.memo(Dropdown);
