import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import SubHead from "../../../Component/Merchant/SubHead";

function Notifications() {
  return (
    <>
      <SubHead head="Notification" first="Home" second="Settings" />
      <Row>
        <Col md={7}>
          <div className="cards">
            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
              <div>
                <small className="d-block f-14 fw-400 DMSans-Regular color-emperor mt-3">
                  Jan, 21 2024{" "}
                </small>
                <ul>
                  <li className="f-20 DMSans-Regular color-black mt-3">
                    <b>
                      Linkoo intrduced a new website Landing Page for QR Code.{" "}
                    </b>
                  </li>
                </ul>
                <p className="f-16  Inter-Regular color-emperor">
                  User can explore new features related to our Products Stay
                  tune for more informations.
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
              <div>
              <small className="d-block f-14 fw-400 DMSans-Regular color-emperor mt-3">
                  Jan, 18 2024{" "}
                </small>
                <ul>
                <li className="f-20 DMSans-Regular color-black mt-3">
                    <b>
                      Linkoo Billing added new Plans for the advanced users.{" "}
                    </b>
                  </li>
                </ul>
                <p className="f-16  Inter-Regular color-emperor">
                  We excited to announce that we have created a new advanced
                  plan for the customized Users who can explore their products
                  through QR Code.
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
              <div>
              <small className="d-block f-14 fw-400 DMSans-Regular color-emperor mt-3">
                  Jan, 12 2024{" "}
                </small>
                <ul>
                <li className="f-20 DMSans-Regular color-black mt-3">
                    <b>Your Current Plan with Linkoo will end soon. </b>
                  </li>
                </ul>
                <p className="f-16  Inter-Regular color-emperor">
                  This is to inform you that your currnet plan with Linkoo will
                  ends in two days. Upgrade soon to enjoy the unlimited service.
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
              <div>
              <small className="d-block f-14 fw-400 DMSans-Regular color-emperor mt-3">
                  Jan, 10 2024{" "}
                </small>
                <ul>
                <li className="f-20 DMSans-Regular color-black mt-3">
                    <b>
                      Linkoo intrduced a new website Landing Page for QR Code.{" "}
                    </b>
                  </li>
                </ul>
                <p className="f-16  Inter-Regular color-emperor">
                  User can explore new features related to our Products Stay
                  tune for more informations.
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center border-bottom py-3">
              <div>
              <small className="d-block f-14 fw-400 DMSans-Regular color-emperor mt-3">
                  Jan, 10 2024{" "}
                </small>
                <ul>
                <li className="f-20 DMSans-Regular color-black mt-3">
                    <b>Language Update. </b>
                  </li>
                </ul>
                <p className="f-16  Inter-Regular color-emperor">
                  Linkoo introduced the website in French Language.
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col md={5}></Col>
      </Row>
    </>
  );
}

export default Notifications;
