import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "./FormTable";
import SubHead from "../../../../Component/Merchant/SubHead";
import SearchBox from "../../../../Component/Merchant/SearchBox";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import { Button } from "@mui/material";
import { useState } from "react";

function Forms() {
    const [codeName, setStatus] = useState([
        {label: 'Code Name', value: 0},
        {label: 'Sample', value: 1},
        {label: 'Test', value: 2}
      ]);
    
      const [codeType, setCodeType] = useState([
        {label: 'Code Type', value: 0},
        {label: 'Static', value: 1},
        {label: 'Dynamic', value: 2}
      ]);
      
    return(
        <>
            <React.Fragment>
                <br/>
                <div>
                <Row>
                    <Col md={6} lg={4}>
                        <SearchBox />
                    </Col>
                    <Col md={12} lg={8}>
                        <Dropdown list={ codeName } />
                        <Dropdown list={ codeType } />
                        <Button variant="contained" className="btn" style={{ backgroundColor: 'white', color: "black", border: '1px solid black', borderRadius: "50px", float: "right" }} >
                            <b>Export</b>
                        </Button>
                    </Col>
                </Row>
            </div>
            <Table />
            </React.Fragment>
        </>
    );
}
export default Forms;