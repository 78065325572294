import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import "../../../assets/Css/style.css";


const plans = [
  {
    title: "Basic Plan",
    price: "$20/",
    days: "30 days",
    restrictuion: "QR Restriction",
    data: "10 codes/Per Month",
    validationTime: "Qr Validation time",
    time: "60 days",
    benfits: "Plan Benfits",
    desc: "Our Features we offers in Basic plan",
    benefits: [" simply dummy text of the printing and typesetting industry.",
      " simply dummy text of the printing and typesetting industry.",
      " simply dummy text of the printing and typesetting industry."]

  },
  {
    title: "Professional Plan",
    price: "$50/",
    days: "30 days",
    restrictuion: "QR Restriction",
    data: "20 codes/Per Month",
    validationTime: "Qr Validation time",
    time: "90 days",
    benfits: "Plan Benfits",
    desc: "Our Features we offers in Professional Plan",
    benefits: [" simply dummy text of the printing and typesetting industry.",
      " simply dummy text of the printing and typesetting industry.",
      " simply dummy text of the printing and typesetting industry."]

  },
  {
    title: "Customized Plan",
    price: "$200/",
    days: "30 days",
    restrictuion: "QR Restriction",
    data: "Unlimited/Per Month",
    validationTime: "Qr Validation time",
    time: "90 days",
    benfits: "Plan Benfits",
    desc: "Our Features we offers in Customized Plan",
    benefits: [" simply dummy text of the printing and typesetting industry.",
      " simply dummy text of the printing and typesetting industry.",
      " simply dummy text of the printing and typesetting industry."]


  },
];

const AvailableBillingPlans = () => {
  return (
    <div className="available-billing-plans">
      {/* <h3 className="text-center mb-4">Choose Your Billing Plan</h3> */}
      <Row className="justify-content-center g-4">
        {plans.map((plan, index) => (
          <Col key={index} md={4}>
            <Card className="text-center shadow-sm h-100 card-custom">
              <Card.Body>
                <Card.Title className="fw-bold">{plan.title}</Card.Title>
                <h2 className="text">{plan.price}<span className="days">{plan.days}</span></h2>
                <Row>
                  <Col md={6}>
                    <h6>{plan.restrictuion}</h6>
                    <p>{plan.data}</p>
                  </Col>
                  <Col md={6}>
                    <h6>{plan.validationTime}</h6>
                    <p>{plan.time}</p>
                  </Col>
                </Row>
                <h5>{plan.benfits}</h5>
                <p>{plan.desc}</p>
                {/* <h4 className="text-restriction">{plan.restrictuion}</h4> */}
                <ul className="list mt-3 mb-4">
                  {plan.benefits.map((benefit, idx) => (
                    <li key={idx} className="mb-2">
                      {benefit}
                    </li>
                  ))}
                </ul>
                <Button variant="primary">Choose Plan</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
        <h6 style={{ textAlign: "center" }}>Subscribe to the Plan that best Suits You</h6>

      </Row>
    </div>
  );
};

export default AvailableBillingPlans;
