import { createSlice } from "@reduxjs/toolkit";
import {
  MerchantOverlay,
} from "../../async-actions/Merchant/setting";

const initialState = {
  status: "",
};

const settingSlice = createSlice({
  name: "merchantsetting",
  initialState,
  extraReducers: {
    [MerchantOverlay.fulfilled]: (state, action) => {
      state.overlay = action.payload;
    },
  },
});

export default settingSlice.reducer;
