import React from 'react';

// Validation function for the SignUp form
const validateForm = (formData, touched) => {
    const errors = {};

    // Validate client_name
    const nameRegex = /^[A-Za-z\s]+$/; // Regex to allow only alphabets and spaces
    if (!formData.client_name.trim() && touched.client_name) {
        errors.client_name = 'Name is required';
    } else if (formData.client_name && !nameRegex.test(formData.client_name) && touched.client_name) {
        errors.client_name = 'Name must contain only alphabets and spaces';
    }

    // Validate mobile_no (ensure it's a valid phone number)
    const phoneRegex = /^[0-9]{10}$/;
    if (!formData.mobile_no.trim() && touched.mobile_no) {
        errors.mobile_no = 'Mobile number is required';
    } else if (formData.mobile_no && !phoneRegex.test(formData.mobile_no) && touched.mobile_no) {
        errors.mobile_no = 'Enter a valid 10-digit mobile number';
    }

    // Validate email_id (ensure it's a valid email)
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!formData.email_id.trim() && touched.email_id) {
        errors.email_id = 'Email is required';
    } else if (formData.email_id && !emailRegex.test(formData.email_id) && touched.email_id) {
        errors.email_id = 'Enter a valid email address';
    }

    // Validate password (ensure it's at least 6 characters long)
    if (!formData.password.trim() && touched.password) {
        errors.password = 'Password is required';
    } else if (formData.password && formData.password.length < 6 && touched.password) {
        errors.password = 'Password must be at least 6 characters long';
    }

    // Validate industry_id (ensure a selection is made)
    if (!formData.industry_id && touched.industry_id) {
        errors.industry_id = 'Please select an industry';
    }

    // Validate subscription_id (ensure a selection is made)
    if (!formData.subscription_id && touched.subscription_id) {
        errors.subscription_id = 'Please select a subscription type';
    }

    return errors;
};

const Validation = ({ formData, touched }) => {
    const errors = validateForm(formData, touched);
    return { errors };
};

export default Validation;
