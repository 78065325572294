import React from "react";

function InputBox(props) {
  const { label, key_name, placeholder, type, onChange } = props;
  return (
    <div className="mt-3">
      <label className="input-label">{label}</label>
      <input
        type={type}
        name={key_name}
        value={props[key_name]}
        className="custom-horizontal-input"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}

export default InputBox;
