import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { LiaCopy } from "react-icons/lia";
import Actions from "./Actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { qr_code_list } from "../../../../Service/ApiUrls";
import api from "../../../../Service/Axios";

export default function AllList() {
  const [copied, setCopied] = useState(false);
  const [qrCodeData, setQrCodeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // Fetch QR Code list
  useEffect(() => {
    const fetchQrCodeData = async () => {
      try {
        const response = await api.get(qr_code_list);
        const result = response.data;
        if (result.status && result.data?.data) {
          const transformedData = result.data.data.map((item) => ({
            id: item.id,
            codeName: item.qrcode_name,
            codeUrl: item.qrcode_value,
            codeType: item.qrcode_type,
            description: item.description,
            location: `${item.location_type}, ${item.location_id}, ${item.lat_long}`,
            ctaType: item.cta_category_name,
            status: item.create_status === "completed" ? "Active" : "Inactive",
          }));

          // Filter QR codes with `qrcode_type` as "static"
          const staticQrCodes = transformedData.filter(
            (code) => code.codeType === "static"
          );

          setQrCodeData(transformedData);
          setFilteredData(staticQrCodes);
        } else {
          console.error("Failed to fetch data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchQrCodeData();
  }, []);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div className="custom-table mt-3">
      <Table>
        <thead>
          <tr className="f-14 fw-500 tableHead">
            <th>Code Name</th>
            <th>Code Url</th>
            <th>Description</th>
            <th>Location</th>
            <th>CTA Type</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((value, key) => (
            <tr key={key}>
              <td className="f-16 fw-500 tableText">{value.codeName}</td>
              <td className="f-16 fw-400 tableText">
                {value.codeUrl}
                <CopyToClipboard text={value.codeUrl} onCopy={handleCopy}>
                  <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                    <LiaCopy />
                    {copied && (
                      <span
                        className="tooltip"
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "5px",
                          backgroundColor: "Grey",
                          color: "black",
                          fontWeight: 500,
                          padding: "5px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          opacity: 0.8,
                          width: "70px",
                        }}
                      >
                        Copied!
                      </span>
                    )}
                  </span>
                </CopyToClipboard>
              </td>
              <td className="f-16 fw-400 tableText">{value.description}</td>
              <td className="f-16 fw-400 tableText">{value.location}</td>
              <td className="f-16 fw-400 tableText">{value.ctaType}</td>
              <td className="f-16 fw-400 tableText">
                <small
                  className={
                    value.status === "Active"
                      ? "status-active"
                      : "status-inactive"
                  }
                >
                  {value.status}
                </small>
              </td>
              <td>
                <div className="mt-3">
                  <Actions />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
