import axios from "axios";
import api from "../Service/Axios";
import { getPreSignedUrl } from "../Service/ApiUrls";
 
export const commonFileUpload = async (data, path) => {
  let date = new Date();
  let final = { type:"templates",files: [] };
 
  for (const [key, values] of Object.entries(data)) {
    // const vasl = `${path}${date.getDate()}-${date.getMonth()}-${date.getFullYear()}_${
    //   values.name
    // }`;
    final.files.push({ fileName: values.name });
  }
 
  try {
    const response = await api.post(getPreSignedUrl, final);
    console.log("upload", response)
    response.data.data.
    presigned_url
    .forEach(async (fileData, index) => {
      try {
        const res = await axios.put(fileData.s3Url, data[index]);
        console.log("res", res)
      } catch (err) {
        console.log("Error uploading file:", err);
      }
    });
  } catch (error) {
    console.error("Error in commonFileUpload:", error);
    throw error;
  }
  return final.files;
};