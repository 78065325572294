import React from "react";

function TermsAndCondition() {
  return (
    <div className="cards mt-3">
      <div className="border-bottom">
        <small className="d-block f-16 fw-600 DMSans-Regular color-black">
          Terms and conditions{" "}
        </small>
        <div style={{maxHeight: "120px", overflowY: "auto"}}>
          <ul>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-3">
        <small className="d-block f-16 fw-600 DMSans-Regular color-black">
          Privacy Policy{" "}
        </small>
        <div style={{maxHeight: "120px", overflowY: "auto"}}>  
          <ul>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
            <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
              Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
              eiusmod tempor incididunt.{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TermsAndCondition;
