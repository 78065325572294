import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

const Event = ({ handleInputChange }) => {
  const [eventData, setEventData] = useState({
    event_name: "",
    event_date: "",
    event_agentas: [{ event_time: "", event_description: "" }],
    cta_name: "",
    end_screen_message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAgentChange = (index, field, value) => {
    const updatedAgentas = [...eventData.event_agentas];
    updatedAgentas[index][field] = value;
    setEventData((prev) => ({
      ...prev,
      event_agentas: updatedAgentas,
    }));
  };

  const addAgent = () => {
    setEventData((prev) => ({
      ...prev,
      event_agentas: [...prev.event_agentas, { event_time: "", event_description: "" }],
    }));
  };

  const removeAgent = (index) => {
    const updatedAgentas = eventData.event_agentas.filter((_, i) => i !== index);
    setEventData((prev) => ({
      ...prev,
      event_agentas: updatedAgentas,
    }));
  };

  useEffect(() => {
    if (
      eventData.event_name &&
      eventData.event_date &&
      eventData.event_agentas.every((agent) => agent.event_time && agent.event_description) &&
      eventData.cta_name &&
      eventData.end_screen_message
    ) {
      const event = {
        target: {
          name: "event",
          value: [eventData],
        },
      };
      handleInputChange(event);
    }
  }, [eventData]);

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}
    >
      <Form.Label className="form-label-h">Event Details</Form.Label>

      {/* Event Name */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter Event Name"
            value={eventData.event_name}
            name="event_name"
            onChange={handleChange}
          />
        </Col>

        {/* Event Date */}
        <Col md={6}>
          <Form.Control
            type="date"
            placeholder="Enter Event Date"
            value={eventData.event_date}
            name="event_date"
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Event Time and Description */}
      <Row className="mb-3">
        <Col md={6}>
          <Button variant="primary" onClick={addAgent} size="sm">
            Add Event Details
          </Button>
        </Col>
      </Row>

      {eventData.event_agentas.map((agent, index) => (
        <div key={index} className="mb-3">
          <Row className="align-items-center">
            {/* Event Time */}
            <Col md={4} className="mb-3">
              <Form.Control
                type="time"
                value={agent.event_time}
                name="event_time"
                onChange={(e) =>
                  handleAgentChange(index, "event_time", e.target.value)
                }
              />
            </Col>

            {/* Event Description */}
            <Col md={4} className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter Event Description"
                value={agent.event_description}
                name="event_description"
                onChange={(e) =>
                  handleAgentChange(index, "event_description", e.target.value)
                }
              />
            </Col>

            {/* Remove Button */}
            <Col md={2}>
              {eventData.event_agentas.length > 1 && (
                <Button
                  variant="danger"
                  onClick={() => removeAgent(index)}
                  size="sm"
                >
                  Remove
                </Button>
              )}
            </Col>
          </Row>
        </div>
      ))}

      {/* CTA Name and End Screen Message */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter CTA Name"
            value={eventData.cta_name}
            name="cta_name"
            onChange={handleChange}
          />
        </Col>

        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter End Screen Message"
            value={eventData.end_screen_message}
            name="end_screen_message"
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Display Added Event Data */}
      {eventData.event_name && eventData.event_date && (
        <Card className="mt-4">
          <Card.Header>Added Event Details</Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <strong>Event Name:</strong> {eventData.event_name}
              </Col>
              <Col md={6}>
                <strong>Event Date:</strong> {eventData.event_date}
              </Col>
            </Row>
            {eventData.event_agentas.map((agent, index) => (
              <Row key={index}>
                <Col md={4}>
                  <strong>Event Time:</strong> {agent.event_time}
                </Col>
                <Col md={4}>
                  <strong>Description:</strong> {agent.event_description}
                </Col>
              </Row>
            ))}
            <Row>
              <Col md={6}>
                <strong>CTA Name:</strong> {eventData.cta_name}
              </Col>
              <Col md={6}>
                <strong>End Screen Message:</strong> {eventData.end_screen_message}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Event;
