import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import SearchBox from "../../../../Component/Merchant/SearchBox";
import AllList from "../AllCodes/AllQRTable"; // Adjust the import path as necessary
import api from "../../../../Service/Axios";
import { cta_categories } from "../../../../Service/ApiUrls";

function Allcode() {
  const [activeStatus, setActiveStatus] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [codeType, setCodeType] = useState(0);
  const [ctaType, setCTAType] = useState(0);
  const [statusOptions, setStatusOptions] = useState([
    { label: "Status", value: 0 },
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
  ]);
  const [codeTypeOptions, setCodeTypeOptions] = useState([
    { label: "Code Type", value: 0 },
    { label: "Static", value: 1 },
    { label: "Dynamic", value: 2 },
  ]);
  const [ctaTypeOptions, setCtaTypeOptions] = useState([]);

  // useEffect(() => {
  //   const fetchCTACategories = async () => {
  //     try {
  //       const response = await api.get(cta_categories);
  //       if (response.data?.data?.cta_categories) {
  //         const ctaOptions = response.data.data.cta_categories.map((item) => ({
  //           label: item.cta_category_name,
  //           value: item.id,
  //         }));
  //         setCtaTypeOptions([{ label: "CTA Type", value: 0 }, ...ctaOptions]);
  //       } else {
  //         console.error("Unexpected API response:", response.data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching CTA categories:", error);
  //       setCtaTypeOptions([{ label: "No CTA Types Available", value: 0 }]);
  //     }
  //   };

  //   fetchCTACategories();
  // }, []);

  useEffect(() => {
    const fetchCTACategories = async () => {
      try {
        const response = await api.get(cta_categories);
        if (response.data?.data?.cta_categories) {
          const ctaOptions = response.data.data.cta_categories.map((item) => ({
            label: item.cta_category_name,
            value: item.id,
          }));
          setCtaTypeOptions([{ label: "CTA Type", value: 0 }, ...ctaOptions]);
        } else {
          console.error("Unexpected API response:", response.data);
        }
      } catch (error) {
        console.error("Error fetching CTA categories:", error);
        setCtaTypeOptions([{ label: "No CTA Types Available", value: 0 }]);
      }
    };
  
    fetchCTACategories();
  }, []);
  

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col md={6} lg={4}>
            <SearchBox key_name="search" onhandlechange={(e) => setSearchTerm(e.target.value)} />
          </Col>
          <Col md={12} lg={8}>
            <Dropdown
              list={statusOptions}
              value={activeStatus}
              onhandlechange={(e) => setActiveStatus(Number(e.target.value))}
            />
            <Dropdown
              list={codeTypeOptions}
              value={codeType}
              onhandlechange={(e) => setCodeType(Number(e.target.value))}
            />
            <Dropdown
              list={ctaTypeOptions}
              value={ctaType}
              onhandlechange={(e) => setCTAType(Number(e.target.value))}
            />
          </Col>
        </Row>
      </div>
      <AllList
        searchTerm={searchTerm}
        activeStatus={activeStatus}
        codeType={codeType}
        ctaType={ctaType}
      />
    </React.Fragment>
  );
}

export default Allcode;