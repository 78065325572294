import React from "react";
import arrowdown from "../../../assets/image/dashboard/arrow-down.svg";


function ChartBox(props) {
  const {element,title} = props;
  return (
    <div className="cards h-100">
        <div className="d-flex justify-content-between">
            <h3 className="DMSans-Regular f-18 fw-500">{title}</h3>
            <span>
              This Month <img src={arrowdown} alt="i" />
            </span>
        </div>
        <div>
            {element}
        </div>  
    </div>
  );
}

export default ChartBox;
