import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
//import arrowdown from "../../../assets/image/dashboard/arrow-down.svg";

function ChartBox(props) {
  const { element, title, chartType } = props;
  const [active, setActive] = useState(1);

  const renderActiveInactive = () => {
    if (chartType === "fullDonut") {
      return (
        <>
          <div className="d-flex align-items-center">
            <div className="status-circle active mr-2" 
            style={{width: "15px", height:"15px", borderRadius: "50%", backgroundColor: "var(--toastify-color-success)", padding: "0px 5px"}}>
            </div>
            <p className="mb-0">&nbsp; Active</p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="status-circle inactive mx-2" 
            style={{width: "15px", height:"15px", borderRadius: "50%", backgroundColor: "#d235c3", padding: "0px 5px"}}>
            </div>
            <p className="mb-0"> Inactive</p>
          </div>
          <div className="d-flex align-items-center">
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>50</p>
            <p style={{ fontSize: "18px", fontWeight: "bold", paddingLeft: "83px" }}>30</p>
          </div>
        </>
      );
      // For Full Donut Chart
    } else {
      return null; 
    }
  };

  return (
    <div className="cards h-100">
      <div className="d-flex justify-content-between">
        <h3 className="f-18 fw-600 DMSans-Regular">{title}</h3>
          <BsThreeDotsVertical className="c-blue cursur"/>
      </div>
      <div className="py-1">{element}</div>
      {/* <ul>
        <li>Active</li>
        <li>Inactive</li>
      </ul> */}
      {/* <div className="d-flex align-items-center">
        <div className="status-circle active mr-2" 
        style={{width: "15px", height:"15px", borderRadius: "50%", backgroundColor: "green", padding: "0px 5px"}}>
        </div>
        <p className="mb-0">&nbsp; Active</p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div className="status-circle inactive mx-2" 
        style={{width: "15px", height:"15px", borderRadius: "50%", backgroundColor: "#d235c3", padding: "0px 5px"}}>
        </div>
        <p className="mb-0"> Inactive</p>
      </div>
      <div className="d-flex align-items-center">
      <p style={{ fontSize: "18px", fontWeight: "bold" }}>50</p>
      <p style={{ fontSize: "18px", fontWeight: "bold", paddingLeft: "83px" }}>30</p>
      </div> */}

      {renderActiveInactive()}

    </div>
  );
}

export default ChartBox;
