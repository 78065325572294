import React, { FunctionComponent } from "react";
import Table from 'react-bootstrap/Table';
export default function DashList() {
    return (   
        <div className="custom-table mt-1">
        <Table>
          <thead>
            <tr className="f-14 fw-500 tableHead">
             <th  style={{color:"#555555"}}>Name</th>
             <th  style={{color:"#555555"}}>Scans</th>
             <th  style={{color:"#555555"}}>Clicks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="f-16 fw-600 tableText">Sample Test</td>
              <td className="f-16 fw-400 tableText">4000</td>
              <td className="f-16 fw-400 tableText">1280</td>
            </tr>
            <tr>
              <td className="f-16 fw-600 tableText">Test 1</td>
              <td className="f-16 fw-400 tableText">2000</td>
              <td className="f-16 fw-400 tableText">1000</td>
            </tr>
            <tr>
              <td className="f-16 fw-600 tableText">Test 2</td>
              <td className="f-16 fw-400 tableText">1000</td>
              <td className="f-16 fw-400 tableText">860</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
}
  