import React from "react";
import Switchbtn from "../../../../Component/Merchant/Forms/Switchbtn";

function Notification() {
  return (
    <div className="cards">
      <small className="d-block f-16 fw-600 DMSans-Regular color-black">
        Notification{" "}
      </small>
      <h5 className="f-14 fw-400 Inter-Regular color-emperor mt-3">
        You can customize your notifications with your controls over the menus
        available in our platform{" "}
      </h5>
      <p className="mb-0 f-16 fw-400 Inter-Regular mt-2">All Notificatios</p>
      <Switchbtn />
      <p className="mb-0 f-16 fw-400 Inter-Regular mt-2">Billing Notification</p>
      <Switchbtn />
      <p className="mb-0 f-16 fw-400 Inter-Regular mt-2">Release note Notification</p>
      <Switchbtn />
    </div>
  );
}

export default Notification;
