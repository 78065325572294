import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import api from "../../../Service/Axios"; // Assuming Axios setup is similar to the previous examples
import SubHead from "../../../Component/Merchant/SubHead";
import SearchBox from "../../../Component/Merchant/SearchBox";
import Dropdown from "../../../Component/Merchant/Forms/Dropdown";
import Loader from "../../../Component/Loader";
import { templates } from "../../../Service/ApiUrls"; // Assuming templates endpoint is defined here

function Template() {
  const [templateList, setTemplateList] = useState([]);
  const [loading, setLoading] = useState(false);

  const codeType = [{ label: "All Templates", value: 0 }];

  // Fetch templates from API
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const response = await api.get(templates);
        console.log("temp res", response);
        // Check if the response contains the expected data
        setTemplateList(response?.data?.data?.template_lists || []); // Ensure safe data access
      } catch (error) {
        console.error("Error fetching templates:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  return (
    <>
      <SubHead head="Templates" first="Home" second="Templates" />
      <div>
        <Row>
          <Col md={6} lg={4}>
            <SearchBox />
          </Col>
          <Col md={12} lg={6}>
            <Dropdown list={codeType} />
          </Col>
        </Row>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <Row className="template-layout mb-3" style={{gap: "10px"}}>
          {templateList.length > 0 ? (
            templateList.map((template, index) => (
              <Col
                key={index}
                className={`template-box ${
                  template.is_default ? "template-boxdefault" : ""
                } mb-4`} // Increased bottom margin for spacing between templates
                md={2}
              >
                {/* Ensure template.image_url exists and is correct */}
                {template.template_url ? (
                  <img
                    className="mx-auto d-block mt-4"
                    src={template.template_url} // Make sure this URL is correct
                    alt={`template-${index}`}
                  />
                ) : (
                  <div className="text-center">No Image Available</div> // Fallback if no image URL
                )}
                <br />
                <Row className="d-flex justify-content-between align-items-center border-bottom"></Row>
                <br />
                <h3 className="Inter-Regular f-16 fw-400 color-emperor text-center">
                  {template.template_name || `Template ${index + 1}`}
                </h3>
              </Col>
            ))
          ) : (
            <div className="text-center w-100">No Templates Found</div>
          )}
        </Row>
      )}
    </>
  );
}

export default Template;
