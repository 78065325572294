export const postLogin = `login`;
export const industries_list = `industries_list`;
export const subscriptions_list = `subscriptions_list`;
export const signup = `signup`;
export const forgotpassword = `forgot-password`;
export const verifyotp = `verify-otp`;
export const resetpassword = `reset-password`;
export const profile_details = `settings/profile_details`;

//qrcode apis
export const qr_code_list = `qr_code_list`;
export const archived_qr_code_list = `archived_qr_code_list`;
export const search = `qr_code/search`;
export const cta_categories = `cta_categories`;
export const qr_code_delete = `qr_code/delete`;
export const qr_code_detail = `qr_code_detail`;
export const locations = `locations`;
export const check_draft_status = `check_draft_status`;
export const qr_code_content = `qr_code/content/`; //id
export const qr_code_design = `qr_code/design/`; //id

export const social_media_types = "social_media_types";
export const getPosters = "posters";

export const getQrCodeDetailsByID = "qr_code/detail/"; //id
export const statistics_appointments = `statistics/appointments`
export const templates = `templates`
export const getPreSignedUrl = `getPreSignedUrl`

export const logout = `logout`
