import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#F3CD46" : "#308fe8",
  },
}));

export default function ProgressBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <p>20mb</p>
      <BorderLinearProgress variant="determinate" value={20} />
      <div className="d-flex justify-content-between">
        <p>0</p>
        <p>20mb</p>
        <p>40mb</p>
        <p>60mb</p>
        <p>100mb</p>
      </div>
    </Box>
  );
}
