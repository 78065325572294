import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import "../../../assets/Css/signup.css";
import "../../../assets/Css/style.css";
import logo from "../../../assets/image/Logo.svg";
import whitebg from "../../../assets/image/whitebg.svg"
import api from "../../../Service/Axios";
import { industries_list, subscriptions_list, signup } from '../../../Service/ApiUrls';
import Validation from '../Settings/utils/SignupValidations';
import Loader from "../../../Component/Loader";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer

function SignUp() {
    const navigate = useNavigate();
    const [industries, setIndustries] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(false); // Track loading state
    const [formData, setFormData] = useState({
        client_name: '',
        email_id: '',
        mobile_no: '',
        password: '',
        industry_id: '',
        subscription_id: '',
        registration_type: 'web'
    });
    const [touched, setTouched] = useState({
        client_name: false,
        email_id: false,
        mobile_no: false,
        password: false,
        industry_id: false,
        subscription_id: false
    });
    const { errors } = Validation({ formData, touched }); // Using Validation function


    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const response = await api.get(industries_list);
                const industriesData = response.data?.data.industries || [];
                setIndustries(industriesData);
            } catch (error) {
                console.error('Error fetching industries:', error);
            }
        };

        const fetchSubscriptions = async () => {
            try {
                const response = await api.get(subscriptions_list);
                const subscriptionsData = response.data?.data.subscriptions || [];
                setSubscriptions(subscriptionsData);
            } catch (error) {
                console.error('Error fetching subscriptions:', error);
            }
        };

        fetchIndustries();
        fetchSubscriptions();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSignup = async (e) => {
        e.preventDefault();
    
        // Check if any of the form fields are empty
        const isFormEmpty = Object.values(formData).some((value) => value === '');
    
        if (isFormEmpty) {
            toast.error("Please fill the form with valid information.");
            return;
        }
    
        // Mark all fields as touched after checking empty fields
        setTouched({
            client_name: true,
            email_id: true,
            mobile_no: true,
            password: true,
            industry_id: true,
            subscription_id: true,
        });
    
        // Check for validation errors in the form data
        const hasErrors = Object.values(errors).some((error) => error);
    
        if (hasErrors) {
            toast.error("Please fill in all required fields correctly.");
            return;
        }
    
        // Show success toast first
        toast.success("Signup successful! Redirecting to login...");
    
        // Show loader after the toast message and redirect after a delay
        setLoading(true);
    
        try {
            const response = await api.post(signup, formData);
            console.log("Signup successful:", response);
    
            // Simulate loading for 2 seconds before navigating
            setTimeout(() => {
                navigate("/login"); // Redirect to login page
            }, 2000);
    
        } catch (error) {
            console.error("Signup error:", error);
            toast.error("Signup failed! Please try again.");
        } finally {
            setLoading(false);
        }
    };
    
    
    

    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched(prevState => ({
            ...prevState,
            [name]: true
        }));
    };

    const handleLoginNavigation = () => {
        setLoading(true); // Set loading to true when the user clicks the "Sign Up" button
        setTimeout(() => {
            navigate("/login");
        }, 2000); // Simulate loading for 2 seconds before navigating
    };

    return (
        <div className="layout-login d-flex align-items-center justify-content-center">
            <ToastContainer position="top-right" autoClose={3000} />
            {loading ? (
                <Loader />
            ) : (
                //  <ToastContainer position="top-right" autoClose={3000} />
                <Row className="signup-box shadow p-4">
                    <Col md={12} lg={6}>
                        <div className="login_userLayout">
                            <Row>
                                <Col className="signup-form" xs={12} sm={6} md={6} lg={6}>
                                    <div className="mt-3 logo-signup">
                                        <img className="signup-logo mt-5 mb-3" src={whitebg} alt="logo" />
                                    </div>
                                    <form onSubmit={handleSignup} className="signup-inputs">
                                        <div className="mt-3">
                                            <div className="DM Sans-Regular color-black f-16 fw-400"> Name </div>
                                            <div className="textbox_layout">
                                                <input
                                                    type="text"
                                                    name="client_name"
                                                    placeholder="Enter your Full name"
                                                    className="text-area"
                                                    value={formData.client_name}
                                                    onChange={handleInputChange}
                                                    onBlur={handleBlur}

                                                />
                                            </div>
                                            {touched.client_name && errors.client_name && (
                                                <span className="error">{errors.client_name}</span>
                                            )}
                                        </div>
                                        <div className="mt-3">
                                            <div className="DM Sans-Regular color-black f-16 fw-400"> Mobile Number </div>
                                            <div className="textbox_layout">
                                                <input
                                                    type="number"
                                                    name="mobile_no"
                                                    placeholder="Enter your Full name"
                                                    className="text-area"
                                                    value={formData.mobile_no}
                                                    onChange={handleInputChange}
                                                    onBlur={handleBlur}

                                                />
                                            </div>
                                            {touched.mobile_no && errors.mobile_no && <span className="error">{errors.mobile_no}</span>}

                                        </div>

                                        <div className="mt-3">
                                            <div className="DM Sans-Regular color-black f-16 fw-400">Industry</div>
                                            <div className="textbox_layout custom-select-container">
                                                <select
                                                    name="industry_id"
                                                    className="text-area custom-select"
                                                    value={formData.industry_id}
                                                    onChange={handleInputChange}
                                                    onBlur={handleBlur}

                                                >
                                                    <option value="" disabled>Select industry below</option>
                                                    {industries.map(industry => (
                                                        <option key={industry.id} value={industry.id}>
                                                            {industry.industry_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {touched.industry_id && errors.industry_id && <span className="error">{errors.industry_id}</span>}
                                        </div>

                                        <div className="mt-3">
                                            <div className="DM Sans-Regular color-black f-16 fw-400">Subscription type</div>
                                            <div className="textbox_layout custom-select-container">
                                                <select
                                                    name="subscription_id"
                                                    className="text-area custom-select"
                                                    value={formData.subscription_id}
                                                    onChange={handleInputChange}
                                                    onBlur={handleBlur}

                                                >
                                                    <option value="" disabled>Select Subscription</option>
                                                    {subscriptions.map(subscription => (
                                                        <option key={subscription.id} value={subscription.id}>
                                                            {subscription.subscription_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {touched.subscription_id && errors.subscription_id && <span className="error">{errors.subscription_id}</span>}
                                        </div>

                                        <div className="mt-3">
                                            <div className="DM Sans-Regular color-black f-16 fw-400"> Email ID </div>
                                            <div className="textbox_layout">
                                                <input
                                                    type="email"
                                                    name="email_id"
                                                    placeholder="example@gmail.com"
                                                    className="text-area"
                                                    value={formData.email_id}
                                                    onChange={handleInputChange}
                                                    onBlur={handleBlur}

                                                />
                                            </div>
                                            {touched.email_id && errors.email_id && <span className="error">{errors.email_id}</span>}

                                        </div>

                                        <div className="mt-3">
                                            <div className="DM Sans-Regular color-black f-16 fw-400">Password</div>
                                            <div className="textbox_layout">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter Password"
                                                    className="text-area"
                                                    value={formData.password}
                                                    onChange={handleInputChange}
                                                    onBlur={handleBlur}

                                                />
                                            </div>
                                            {touched.password && errors.password && <span className="error">{errors.password}</span>}

                                        </div>

                                        <div className="d-flex align-items-center mt-3 checkbox-container">
                                            <input type="checkbox" id="rememberMe" className="me-2" />
                                            <label htmlFor="rememberMe" className="checkbox-label">
                                                By signing up, you agree to our Terms & Conditions and Privacy Policy
                                            </label>
                                        </div>

                                        <div className="mt-3 singUp">
                                            <button type="submit" className="btn_login color-white d-flex bgcolor-cerise">
                                                <span className="DMSans-Regular f-18 fw-600 color-white">SignUp</span>
                                            </button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={6} className="login_headLayout">
                        <h5 className="signup_head color-black DMSans-Regular f-32 fw-600">Already have an Existing account?</h5>
                        <h5 className="signup_subHead color-gray Inter-Regular f-14 fw-400">
                            Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt.
                        </h5>
                        <Button type="button" className="btn-login mt-3" onClick={handleLoginNavigation}>
                            Login!
                        </Button>
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default SignUp;
