import React, { useState } from "react";
import SubHead from "../../../Component/Merchant/SubHead";
import { SettingTabs } from "./utils/utils";
import Tabs from "../../../Component/Merchant/Tabs";
import UserManagement from "./User-Management";
import AccountInformation from "./Account-Information";
import AvailableBillingPlans from "./AvailableBillingPlans";
import Faq from "./Faq";
import Feedback from "./Feedback";

function Settings() {
  const [activeId, setActiveId] = useState(1);

  const getSubHeadThird = () => {
    switch (activeId) {
      case 1:
        return "Account Information";
      case 2:
        return "User Management";
      case 3:
        return "Available Billing Plans";
      case 4:
        return "FAQ";
      case 5:
        return "Feedback Management";
      default:
        return "";
    }
  };
  
  return (
    <div>
      <SubHead head="Settings" first="Home" second="Settings" third={getSubHeadThird()}/>
      <Tabs
        values={SettingTabs}
        activeId={activeId}
        setActiveId={setActiveId}
      />{" "}
      {activeId === 1 && <AccountInformation />}
      {activeId === 2 && <UserManagement />}
      {activeId === 3 && <AvailableBillingPlans />}
      {activeId === 4 && <Faq />}
      {activeId === 5 && <Feedback />}
    </div>
  );
}

export default Settings;
