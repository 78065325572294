import React from "react";
import homew from "../assets/image/Merchant/menu/home_w.svg";
import homep from "../assets/image/Merchant/menu/home_p.svg";
import qrcodew from "../assets/image/Merchant/menu/qrcode_w.svg";
import qrcodep from "../assets/image/Merchant/menu/qrcode_p.svg";
import templatew from "../assets/image/Merchant/menu/template_w.svg";
import templatep from "../assets/image/Merchant/menu/template_p.svg";
import billingw from "../assets/image/Merchant/menu/creditcard_w.svg";
import billingp from "../assets/image/Merchant/menu/billing_p.svg";
import statisticsw from "../assets/image/Merchant/menu/chart_average_w.svg";
import statisticsp from "../assets/image/Merchant/menu/statistics_p.svg";
import settingw from "../assets/image/Merchant/menu/setting_w.svg";
import settingp from "../assets/image/Merchant/menu/setting_p.svg";

export const SidebarDataMerchant = [
  {
    title: "Home",
    path: "home",
    name: "dashboard",
    icon: <img src={homew} className="side-icon" />,
    icon1: <img src={homep} className="side-icon" />,
  },
  {
    title: "QR Code",
    path: "qrcode",
    name: "campaign management",
    icon: <img src={qrcodew} className="side-icon" />,
    icon1: <img src={qrcodep} className="side-icon" />,
  },
  {
    title: "Template",
    path: "template",
    name: "masters",
    icon: <img src={templatew} className="side-icon" />,
    icon1: <img src={templatep} className="side-icon" />,
  },
  {
    title: "Billing",
    path: "billing",
    name: "masters",
    icon: <img src={billingw} className="side-icon" />,
    icon1: <img src={billingp} className="side-icon" />,
  },

  {
    title: "Statistics",
    path: "statistics",
    name: "user management",
    icon: <img src={statisticsw} className="side-icon" />,
    icon1: <img src={statisticsp} className="side-icon" />,
  },

  {
    title: "Settings",
    path: "settings",
    name: "settings",
    icon: <img src={settingw} className="side-icon" />,
    icon1: <img src={settingp} className="side-icon" />,
  },
];
