import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import Static from "../../../Component/Merchant/Charts/HalfDonutChart";
import Dynamic from "../../../Component/Merchant/Charts/HalfDonutChart";
import Tabs from "../../../Component/Merchant/Tabs";
import { DashTabs } from "../../../pages/Merchant/Dashboard/Utils/utils";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
Chart.register(ArcElement);

const data = {
  datasets: [
    {
        data: [10, 3],
        backgroundColor: [
          "#5EB02B",
          "#E9E9E9"
        ],
        display: true,
        borderColor: "#D1D6DC"
    }
  ]
};

const HalfDoChart = () => {
  const [activeId, setActiveId] = useState(1);
  return (
    <div>
      <Row >
        <Col md={12} lg={18}>
          <Tabs
            values={DashTabs}
            activeId={activeId}
            setActiveId={setActiveId}
          />{" "}
        </Col>
      </Row>
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          rotation: -90,
          circumference: 180,
          cutout: "60%",
          maintainAspectRatio: true,
          responsive: true
        }}
      />
      {/* {activeId === 1 && <Static />}
      {activeId === 2 && <Dynamic />} */}
      <div
        style={{
          position: "absolute",
          top: "78%",
          left: "84%",
          transform: "translate(-50%, -50%)",
          textAlign: "center"
        }}
      >
        <div className="DMSans-Regular f-18 fw-600">80%</div>
      </div>
    </div>
  );
};

export default HalfDoChart;