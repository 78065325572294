import React from "react";
import { Col, Row } from "react-bootstrap";
import Table from "./DynamicQRTable";
import SubHead from "../../../../Component/Merchant/SubHead";
import SearchBox from "../../../../Component/Merchant/SearchBox";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import { useState } from "react";

function DynamicQRCode() {

    
  const [activeStatus, setActiveStatus] = useState();

  const [status, setStatus] = useState([
    {label: 'Status', value: 0},
    {label: 'Active', value: 1},
    {label: 'Inactive', value: 2}
  ]);

  const [codeType, setCodeType] = useState([
    {label: 'Code Type', value: 0},
    {label: 'Static', value: 1},
    {label: 'Dynamic', value: 2}
  ]);
  const [ctaType, setCTAType] = useState([
    {label: 'CTA Type', value: 0},
    {label: 'CTA Type 1 ', value: 1},
    {label: 'CTA Type 2', value: 2}
  ]);

  const handleChange = (event) => {
    setActiveStatus(event.target.value);
  };

    return (
        <>
            <React.Fragment>
                <br/>
                <div>
                <Row>
                    <Col md={6} lg={4}>
                        <SearchBox />
                    </Col>
                    <Col md={12} lg={8}>
                        <Dropdown list={ status } onhandlechange={handleChange}/>
                        <Dropdown list={ codeType } onhandlechange={handleChange} />
                        <Dropdown list={ ctaType } onhandlechange={handleChange} />
                    </Col>
                </Row>
            </div> 
             <Table />
            </React.Fragment>
        </>
    );
}
export default DynamicQRCode;
