import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../../reducers/Merchant/authSlice";
import settingSlice from "../../reducers/Merchant/settingSlice";
import profileslice from "../../reducers/Merchant/profileslice";


const Store = configureStore({
  reducer: {
    // merchant_auth: authSlice,
    // setting: settingSlice,
    profile: profileslice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default Store;
