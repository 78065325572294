import React from "react";
import "../../../../assets/Css/MobileFrame.css";

const MobileFrame = ({ children, formContent }) => {
  // Get the current time
  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="mobile-frame">
      {/* Mock status bar */}
      <div className="mobile-status-bar">
        <div className="status-left">
          <span className="wifi-icon">📶</span> {/* Mock WiFi icon */}
          <span className="sim-icon">📡</span> {/* Mock SIM icon */}
        </div>
        <div className="status-center">
          <span className="time">{getCurrentTime()}</span> {/* Display current time */}
        </div>
        <div className="status-right">
          <span className="battery-icon">🔋</span> {/* Mock battery icon */}
        </div>
      </div>

      {/* Main screen content */}
      <div className="mobile-screen">{children}
      {/* {formContent} */}
      </div>
       {/* Form content placed outside of mobile-screen */}
       
    </div>
  );
};

export default MobileFrame;
