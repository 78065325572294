import React from "react";
import { Table } from "react-bootstrap";
import bin from "../../../../assets/image/bin.svg";
import edit from "../../../../assets/image/edit.svg";

function UserTable() {
  return (
    <div className="custom-table mt-3">
      <Table>
        <thead>
          <tr className="f-14 fw-500 tableHead">
            <th>User Name </th>
            <th>Email Id</th>
            <th>Role</th>
            <th>Permissions</th>
            <th>Creation Date</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="f-16 fw-500 tableText">Dave Jhon</td>
            <td className="f-16 fw-400 tableText">davejohn_16@gmail.com</td>
            <td className="f-16 fw-400 tableText">Admin</td>
            <td className="f-16 fw-400 tableText">Full Access</td>
            <td className="f-16 fw-400 tableText">October 10, 2022</td>
            <td className="f-16 fw-400 tableText">
              <small className="status-active">Paid</small>
            </td>
            <td>
              <img src={edit} alt="i" className="mt-3 me-3 cursor" />
              <img src={bin} alt="i" className="mt-3 cursor" />
            </td>
          </tr>
          <tr>
          <td className="f-16 fw-500 tableText">Dave Jhon</td>
            <td className="f-16 fw-400 tableText">davejohn_16@gmail.com</td>
            <td className="f-16 fw-400 tableText">Admin</td>
            <td className="f-16 fw-400 tableText">Full Access</td>
            <td className="f-16 fw-400 tableText">October 10, 2022</td>
            <td className="f-16 fw-400 tableText">
              <small className="status-inactive">Inactive</small>
            </td>
            <td>
              <img src={edit} alt="i" className="mt-3 me-3 cursor" />
              <img src={bin} alt="i" className="mt-3 cursor" />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default UserTable;
