import React from "react";
import { Col, Row } from "react-bootstrap";
import circlecheck from "../../../../assets/image/qrcode/circle-check.svg";
import line from "../../../../assets/image/qrcode/Line.svg";
import circlecheckfull from "../../../../assets/image/qrcode/circle-checkfull.svg";
import "../../../../assets/Css/style.css"; // Ensure you create a CSS file for custom styles

function Steps(props) {
  const { activeId } = props;
  return (
    <div>
      <Row>
        <Col>
          <h3 className="Inter-Regular f-16 fw-500 text-center color-emperor">
            Steps{" "}
            <span className="Inter-Regular f-16 fw-700 text-center color-black">
              {activeId}
            </span>{" "}
            <span className="Inter-Regular f-16 fw-500 text-center color-emperor">
              of 3
            </span>
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <Row className="steps-row">
            <Col xs={4} md={3} className="mb-3">
              <img
                src={activeId > 1 ? circlecheckfull : circlecheck}
                alt=""
              />
              <h3 className="Inter-Regular f-16 fw-500 color-emperor">Details</h3>
            </Col>
            <Col xs={1} md={1} className="mb-3">
              <img src={line} alt="" className="step-line" />
            </Col>
            <Col xs={4} md={3} className="mb-3">
              <img
                src={activeId > 2 ? circlecheckfull : circlecheck}
                alt=""
              />
              <h3 className="Inter-Regular f-16 fw-500 color-emperor">Contents</h3>
            </Col>
            <Col xs={1} md={1} className="mb-3">
              <img src={line} alt="" className="step-line" />
            </Col>
            <Col xs={4} md={3} className="mb-3">
              <img
                src={activeId > 3 ? circlecheckfull : circlecheck}
                alt=""
              />
              <h3 className="Inter-Regular f-16 fw-500 color-emperor">Design</h3>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Steps;
