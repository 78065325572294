import React from "react";
import Information from "./Information";
import { Col, Row } from "react-bootstrap";
import Notification from "./Notification";
import TermsAndCondition from "./TermsAndCondition";
function AccountInformation() {
  return (
    <Row>
      <Col md={7}>
        <Information />
      </Col>
      <Col md={5}>
        <Notification />
        <TermsAndCondition />
      </Col>
    </Row>
  );
}

export default AccountInformation;
