import React from "react";
// import logo from '.'

function Loader() {
  return (
    <div className="container-lod">
      <div className="loader">
      <div className="loader--img">
          {/* <img src={logo} width="90" /> */}
        </div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--dot"></div>
        <div className="loader--text"></div>
      </div>
    </div>
  );
}

export default Loader;
