import React, { useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../../assets/Css/style.css";
import "../../../assets/Css/login.css";
import whitebg from "../../../assets/image/whitebg.svg";
import logo from "../../../assets/image/Logo.svg";
import { postLogin } from "../../../Service/ApiUrls"; // Ensure this URL is correct
import api from "../../../Service/Axios"; // Ensure this axios instance is configured
import { toast } from "react-toastify"; // Import the toast function
import "react-toastify/dist/ReactToastify.css"; // Import the styles for react-toastify
import Loader from "../../../Component/Loader";
import Validation from "../Settings/utils/Validations";

function Login() {
  const [credential, setCredential] = useState({ email_id: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [touched, setTouched] = useState({ email_id: false, password: false }); // Track touched state
  const [validationErrors, setValidationErrors] = useState({ email_id: "", password: "" });
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!credential.email_id || !credential.password) {
      toast.error("Please fill in all fields.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (validationErrors.email_id || validationErrors.password) {
      toast.error("Please correct the validation errors.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    try {
      setLoading(true);
      const response = await api.post(postLogin, credential);
      const token = response?.data?.data?.api_token;

      if (token) {
        localStorage.setItem("token", token);
        toast.success("Login successful!", {
          position: "top-right",
          autoClose: 3000,
        });
        navigate("/home");
      } else {
        toast.error("Invalid credentials.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Login failed, please try again.";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredential((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    setTouched((prevState) => ({ ...prevState, [name]: true })); // Mark the field as touched
  };

  // Function to handle navigation to signup page
  const handleSignUp = () => {
    setLoading(true); // Set loading to true when the user clicks the "Sign Up" button
    setTimeout(() => {
      navigate("/signup");
    }, 2000); // Simulate loading for 2 seconds before navigating
  };
  const handleForgetPassword = () => {
    setLoading(true); // Set loading to true when the user clicks the "Sign Up" button
    setTimeout(() => {
      navigate("/forgetPassword");
    }, 2000); // Simulate loading for 2 seconds before navigating
  };

  return (
    <Container className="login-container d-flex align-items-center justify-content-center">
      {loading ? <Loader /> : (
        <Row className="login-box shadow p-4">
          <Col md={6} className="login-info d-flex flex-column align-items-start justify-content-center p-3">
            <div className="sing-in-content">
              <h5 className="login-title">Don’t have an Existing account?</h5>
              <h5 className="login_subHead color-gray Inter-Regular f-14 fw-400">
                Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt.
              </h5>

              <Button variant="outline-primary" className="sign-up-btn mt-2" onClick={handleSignUp}>
                Sign up!
              </Button>
            </div>
          </Col>

          <Col md={6} className="login-form">
            <div className="form-class">
              <img src={whitebg} alt="logo" className="login-logo mb-3" />
              <div className="mt-3">
                <div className="DM Sans-Regular color-black f-16 fw-400">
                  Email ID
                </div>
                <div className="login_textbox_layout">
                  <input
                    type="email"
                    placeholder="example@gmail.com"
                    className="text-area"
                    value={credential?.email_id}
                    name="email_id"
                    onChange={handleChange}
                    onBlur={handleBlur} // Mark the field as touched on blur
                  />

                </div>
                {touched.email_id && validationErrors.email_id && (
                  <div className="error-message mt-2">{validationErrors.email_id}</div>
                )}
              </div>

              <div className="mt-3">
                <div className="DM Sans-Regular color-black f-16 fw-400">
                  Password
                </div>
                <div className="login_textbox_layout">
                  <input
                    type="password"
                    placeholder="Enter Password"
                    className="text-area"
                    value={credential?.password}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur} // Mark the field as touched on blur
                  />

                </div>
                {touched.password && validationErrors.password && (
                  <div className="error-message mt-2">{validationErrors.password}</div>
                )}
              </div>

              <Row className="mt-3 align-items-center">
                <Col xs={6}>
                  <div className="d-flex align-items-center">
                    <input type="checkbox" className="me-2" />
                    <label htmlFor="rememberMe" className="DM Sans-Regular color-black f-14 fw-400">
                      Remember me
                    </label>
                  </div>
                </Col>
                <Col xs={6} className="text-end">
                  <a href="javascript:void(0);" onClick={handleForgetPassword} className="forgot-password">
                    Forgot Password?
                  </a>
                </Col>
              </Row>

              <Button
                type="button"
                className="btn_login w-100 bgcolor-cerise color-white mt-3"
                onClick={handleSubmit}
              >
                Login
              </Button>
              {/* {errorMessage && <div className="error-message mt-2">{errorMessage}</div>} */}
            </div>
          </Col>
        </Row>
      )}

      <Validation credentials={credential} touched={touched} setError={setValidationErrors} />
    </Container>
  );
}

export default Login;
