import React, { useState, useEffect } from "react";
import { MdNotificationsActive } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { RxHamburgerMenu } from 'react-icons/rx'
import { useDispatch, useSelector } from "react-redux";
import notification from "../assets/image/notification.svg";
import { useNavigate, Link } from "react-router-dom";
import api from "../Service/Axios";
import { profile_details } from "../Service/ApiUrls";
import { ProfileDetails } from "../redux/async-actions/Merchant/profile";

function Navbar({ width, showSidebar }) {
  const navigate = useNavigate();
  const Profile = useSelector((state) => state?.profile?.profile);
  const dispatch = useDispatch()

  // Use useEffect to fetch user information on component mount
  useEffect(() => {
    // Make an API request to get profile details
    api
      .get(profile_details) // The endpoint to fetch user details
      .then((response) => {
        // Check if the response has the expected structure and update state
        if (response.data && response.data.data && response.data.data.client_detail) {
          dispatch(ProfileDetails(response.data))
          console.log("dispatch", response.data)
          // setUserInfo({
          //   client_name: response.data.data.client_detail.client_name, 
          //   role: response.data.data.client_detail.role_name, 
          // });
        }
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, []); // Empty dependency array ensures this runs once on mount
  return (
    <div
      className={`${width} header header-nav d-flex justify-content-between align-items-center`}
    >
      <div>
        <span>
          <RxHamburgerMenu className="ham-icon cursor" onClick={showSidebar} />
        </span>
        <p className="mb-0 location grey-c f-16 fw-500 Inter-Regular">
          <HiOutlineLocationMarker className="f-32 fw-800" /> Mappedu, East Tambaram,
          Chennai 600 126
        </p>
      </div>
      <div>
        <ul className="profile-box mb-0">
          <li>
            <Link to="/notificationIcon">
              <img src={notification} className="mt-2 mx-2" alt="i" />
            </Link>
          </li>
          <li>
            <span>
              {false ? (
                <img className="nav-box" src={Profile?.profile_image} alt="i" />
              ) : (
                <img
                  className="nav-box"
                  src={require("../assets/image/avatar.png")}
                  alt="i"
                />
              )}
            </span>
          </li>
          <li>
            <span className="user mt-2">
              <small className="name DMSans-Regular f-16 fw-600">
                {Profile.client_name}
              </small>
              <br></br>
              <small className="Inter-Regular user-mail f-14 fw-500">
                {Profile.role_name}              
              </small>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
