import React from "react";

function Plans({ planType, availability, validity, price }) {
  return (
    <div className="cards">
      <small className="d-block f-16 fw-600 DMSans-Regular color-black">
        {/* Basic plan{" "} */}
        {planType} Plan
      </small>
      <div className="mt-4">
        {" "}
        <span className="DMSans-Regular color-black f-24 fw-600">
          $ { price }
          <span className="Inter-Regular f-16 fw-400">/30 days</span>
        </span>
      </div>
      <div className="d-flex mt-2" style={{ gap: "10px" }}>
        <div>
          <small className="d-block f-16 fw-600 DMSans-Regular color-black mt-2">
            QR Restriction{" "}
          </small>
          <h5 className="f-14 fw-400 Inter-Regular color-black mt-2">
            { availability }/Per month{" "}
          </h5>
        </div>
        <div style={{ paddingLeft: "10px" }}>
          <small className="d-block f-16 fw-600 DMSans-Regular color-black mt-2">
            QR validation time
          </small>
          <h5 className="f-14 fw-400 Inter-Regular color-black mt-2">{validity} days </h5>
        </div>
      </div>
      <div>
        <small className="d-block f-16 fw-600 DMSans-Regular color-black mt-2">
          Plan Benefits{" "}
        </small>
        <h5 className="f-14 fw-400 Inter-Regular color-black mt-2">
          Our Features we offers in Professional Plan
        </h5>
        <ul>
          <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
            Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
            eiusmod tempor incididunt.{" "}
          </li>
          <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
            Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
            eiusmod tempor incididunt.{" "}
          </li>
          <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
            Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
            eiusmod tempor incididunt.{" "}
          </li>
          <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
            Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
            eiusmod tempor incididunt.{" "}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Plans;
