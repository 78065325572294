import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Card, Col, Form, Row } from "react-bootstrap";

const Appointment = ({ formData, handleInputChange }) => {
  const [appointmentData, setAppointmentData] = useState({
    cta_name: "",
    end_screen_message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  

  useEffect(() => {
    const { cta_name, end_screen_message } = appointmentData;
    if (cta_name && end_screen_message) {
      // Trigger parent function to handle the data
      const event = {
        target: {
          name: "appointments",
          value: [appointmentData],
        },
      };
      handleInputChange(event);
    }
    
  }, [appointmentData])

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}
    >
      <Row className="mb-3">
        <Form.Label htmlFor="ctaName" className="form-label-h">
          Appointment Details
        </Form.Label>
        <Col md={4}>
          <Form.Control
            type="text"
            placeholder="Enter CTA Name"
            value={appointmentData.cta_name}
            name="cta_name"
            onChange={handleChange}

          />
        </Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Enter Description"
            value={appointmentData.end_screen_message}
            name="end_screen_message"
            onChange={handleChange}
          />
        </Col>
        {/* <Col md={2}>
          <Button
            className="ms-2"
            variant="contained"
            onClick={handleAddAppointment}
          >
            Add Appointment
          </Button>
        </Col> */}
      </Row>

     
      {/* {formData?.appointment_details && (
        <Card className="mt-4">
          <Card.Header>
            Added Appointment Details
          </Card.Header>
          <Card.Body>
            <Row className="mb-2">
              <Col md={4}>
                <strong>CTA Name:</strong> {formData.appointment_details.cta_name}
              </Col>
              <Col>
                <strong>Description:</strong> {formData.appointment_details.description}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )} */}
    </div>
  );
};

export default Appointment;
