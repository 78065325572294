import React, { useState } from "react";
import { Row, Col, Container, Button, Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Ensure you import the toast CSS
import "../../../assets/Css/style.css";
import "../../../assets/Css/forgetpass.css";
import logo from "../../../assets/image/Logo.svg";
import whitebg from "../../../assets/image/whitebg.svg";
import { forgotpassword, verifyotp, resetpassword } from "../../../Service/ApiUrls";
import api from "../../../Service/Axios";

function ForgotPassword() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleEmailSubmit = async () => {
    try {
      await api.post(forgotpassword, { email_id: email });
      toast.success("OTP sent to your email.", {
        position: "top-right",
        autoClose: 5000,
      });
      setStep(2);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Error sending OTP.");
    }
  };

  const handleOtpSubmit = async () => {
    try {
      await api.post(verifyotp, { email_id: email, otp_code: otp });
      toast.success("OTP verified successfully.", {
        position: "top-right",
        autoClose: 5000,
      });
      setStep(3);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Invalid OTP.");
    }
  };

  const handlePasswordReset = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }
    try {
      await api.post(resetpassword, { email_id: email, password, password_confirmation: confirmPassword });
      toast.success("Password reset successfully. Redirecting to login...", {
        position: "top-right",
        autoClose: 5000,
      });
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Error resetting password.");
    }
  };

  return (
    <Container className="forgot-container d-flex align-items-center justify-content-center">
      <Row className="forgot-box shadow p-4">
        <Col md={6} className="forgot-info d-flex flex-column align-items-start justify-content-center p-3">
          <div className="forgot-in-content">
            <h5 className="forgot-title">Reset Your Password</h5>
            <h5 className="forgot_subHead color-gray Inter-Regular f-14 fw-400">
              Enter your email to receive an OTP and reset your password.
            </h5>
          </div>
        </Col>

        <Col md={6} className="forgot-form">
          <div className="forgot-class">
            <img src={whitebg} alt="logo" className="forgot-logo mb-3" />
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            
            {step === 1 && (
              <Form>
                <Form.Group controlId="email">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="example@gmail.com"
                    className="forgot_text-area"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Button className="btn_forgot w-100 mt-3" onClick={handleEmailSubmit}>
                  Send OTP
                </Button>
              </Form>
            )}

            {step === 2 && (
              <Form>
                <Form.Group controlId="otp">
                  <Form.Label>OTP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP sent to your email"
                    className="forgot_text-area"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </Form.Group>
                <Button className="btn_forgot w-100 mt-3" onClick={handleOtpSubmit}>
                  Verify OTP
                </Button>
              </Form>
            )}

            {step === 3 && (
              <Form>
                <Form.Group controlId="password">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    className="forgot_text-area"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="confirmPassword" className="mt-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm new password"
                    className="forgot_text-area"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
                <Button className="btn_login w-100 mt-3" onClick={handlePasswordReset}>
                  Reset Password
                </Button>
              </Form>
            )}
          </div>
        </Col>
      </Row>

      <ToastContainer /> {/* Add ToastContainer here */}
    </Container>
  );
}

export default ForgotPassword;
