import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";

function Dropdown({ list, key_name, value, onhandlechange, placeholder }) {
  return (
    <FormControl sx={{ marginRight: "10px", width: 200 }} size="small">
      <Select
        labelId={`${key_name}-select-label`}
        id={`${key_name}-select`}
        name={key_name}
        value={value || 0} // Ensure fallback value
        onChange={onhandlechange}
        displayEmpty
        IconComponent={ExpandMoreOutlined}
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "24px",
        }}
      >
        <MenuItem value={0} disabled>
          {placeholder || "Select an option"} {/* Placeholder for default */}
        </MenuItem>
        {list?.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

Dropdown.propTypes = {
  list: PropTypes.array.isRequired, // List of options to display
  key_name: PropTypes.string.isRequired, // Unique key for the dropdown
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Selected value
  onhandlechange: PropTypes.func.isRequired, // Change handler
  placeholder: PropTypes.string, // Placeholder text
};

Dropdown.defaultProps = {
  value: 0,
  placeholder: "Select an option",
};

export default React.memo(Dropdown);
