import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "../../../service/Merchant/axios";

export const clearMerchantSetting = createAction('clearMerchantsetting')

export const ProfileDetails = createAsyncThunk(
  `merchant/ProfileDetails`,
  async (data) => {
    const response = data.data.client_detail
    console.log("res", response)
    return await response;
  }
);
