import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Card, Col, Form, Row } from "react-bootstrap";

const Links = ({ handleInputChange }) => {
  const [links, setLinks] = useState([]); // Array of link objects
  const [newLink, setNewLink] = useState("");

  const handleAddLink = () => {
    if (newLink) {
      const updatedLinks = [...links, { link: newLink }]; // Add the new link in the required format
      setLinks(updatedLinks);
      setNewLink("");
    }
  };

  const handleDeleteLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index); // Remove link at specified index
    setLinks(updatedLinks);
  };

  // Use useEffect to send the updated links array to the parent component
  useEffect(() => {
    const event = {
      target: {
        name: "links",
        value: links,
      },
    };
    handleInputChange(event); // Trigger the parent function with updated links
  }, [links]);

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}
    >
      <Row className="mb-3">
        <Form.Label htmlFor="linkInput" className="form-label-h">
          Enter URL
        </Form.Label>
        <Col md={8}>
          <Form.Control
            type="url"
            placeholder="Enter URL"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            name="url"
            required
          />
        </Col>
        <Col md={4}>
          <Button
            variant="contained"
            className="ms-2"
            onClick={handleAddLink}
            disabled={!newLink} // Disable button if input is empty
          >
            Add New Link
          </Button>
        </Col>
      </Row>

      {links.length > 0 && (
        <Card className="mt-4">
          <Card.Header>Added Links</Card.Header>
          <Card.Body>
            {links.map((item, index) => (
              <Row key={index} className="mb-2 align-items-center">
                <Col md={8}>
                  <Form.Control
                    type="text"
                    className="form-control"
                    value={item.link} // Access the 'link' property
                    readOnly // Make the input read-only
                  />
                </Col>
                <Col md={4}>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => handleDeleteLink(index)}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            ))}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Links;
