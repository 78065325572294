import React, { useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const SidebarNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 17px;
  padding: 20px 7px;
  list-style: none;
  position: relative;
  height: 60px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  font-family: Gilroy-bold;
  width: ${({ sidebar }) => (!sidebar ? "92px" : "92px")};

  &:hover {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 190px;
      width: 0px;
      height: 0px;
      border-bottom: 20px solid #f8f8f8;
      border-left: 20px solid #fff;
      -webkit-box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.3);
      transform: rotate(90deg);
    }
    // background: #f8f8f8;
    // box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.45);
    cursor: pointer;
    color: #000;

    &.active {
      color: #fff;
    }
  }
`;
const SidebarLabel = styled.span`
  display: block;
  color: white;
  font-family: Gilroy-regular;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
`;

const SubMenu = ({ item, sidebar }) => {
  const location = useLocation();
  const isActive = location.pathname.split("/")[1] === item.path;
  return (
    <>
      <SidebarNavLink
        className="link"
        sidebar={sidebar}
        to={item.path}
        onClick={item.subNav}
      >
        <div>
          <div className="menu-icon mb-2">
            <i> {isActive ? item.icon1 : item?.icon}</i>
          </div>
          <SidebarLabel className="menu-name">{item.title}</SidebarLabel>
        </div>
      </SidebarNavLink>
    </>
  );
};

export default SubMenu;
