import React, { FunctionComponent } from "react";
import Table from 'react-bootstrap/Table';

const allcode = [
    {
      username: "Stephen James",
      mailid: "stephen_james23@gmail.com",
      mobile: "9859430438",
      message: "Lorem ipsum dolor sit amet, consectetur",
      codetype: "Static",
      codename: "Sample Video",
      status: "Active"
    },
    {
        username: "Peter Hendry",
        mailid: "peterhendry5@yahoo.com",
        mobile: "85950348203",
        message: "Lorem ipsum dolor sit amet, consectetur",
        codetype: "Dynamic",
        codename: "Test",
        status: "Inactive"
    },
    {
        username: "Antony Brito",
        mailid: "antonybrito_8@gmail.com",
        mobile: "857596037408",
        message: "Lorem ipsum dolor sit amet, consectetur",
        codetype: "Dynamic",
        codename: "Introduction video",
        status: "Active"
    },
    {
        username: "Stephen James",
        mailid: "stephen_james23@gmail.com",
        mobile: "9859430438",
        message: "Lorem ipsum dolor sit amet, consectetur",
        codetype: "Static",
        codename: "Sample Video",
        status: "Active"
    },
    {
        username: "Peter Hendry",
        mailid: "peterhendry5@yahoo.com",
        mobile: "85950348203",
        message: "Lorem ipsum dolor sit amet, consectetur",
        codetype: "Dynamic",
        codename: "Test",
        status: "Inactive"
    },
    {
        username: "Antony Brito",
        mailid: "antonybrito_8@gmail.com",
        mobile: "857596037408",
        message: "Lorem ipsum dolor sit amet, consectetur",
        codetype: "Dynamic",
        codename: "Introduction video",
        status: "Active"
    },
    {
        username: "Stephen James",
        mailid: "stephen_james23@gmail.com",
        mobile: "9859430438",
        message: "Lorem ipsum dolor sit amet, consectetur",
        codetype: "Static",
        codename: "Sample Video",
        status: "Active"
    },
    {
        username: "Peter Hendry",
        mailid: "peterhendry5@yahoo.com",
        mobile: "85950348203",
        message: "Lorem ipsum dolor sit amet, consectetur",
        codetype: "Dynamic",
        codename: "Test",
        status: "Inactive"
    },
    {
        username: "Antony Brito",
        mailid: "antonybrito_8@gmail.com",
        mobile: "857596037408",
        message: "Lorem ipsum dolor sit amet, consectetur",
        codetype: "Dynamic",
        codename: "Introduction video",
        status: "Active"
    },
  ];
  
  export default function AllList() {
    return (   
        <div className="custom-table mt-3">
          <Table>
            <thead>
              <tr className="f-14 fw-500 tableHead">
                <th>User Name</th>
                <th>Email Id</th>
                <th>Mobile Number</th>
                <th>Minutes of Meetings</th>
                <th>Code Type</th>
                <th>Code Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {allcode.map((value, key) => {
                return (
                  <tr key={key}>
                    <td className="f-16 fw-500 tableText">{value.username}</td>
                    <td className="f-16 fw-400 tableText">{value.mailid}</td>
                    <td className="f-16 fw-400 tableText">{value.mobile}</td>
                    <td className="f-16 fw-400 tableText">{value.message}</td>
                    <td className="f-16 fw-400 tableText">{value.codetype}</td>
                    <td className="f-16 fw-400 tableText">{value.codename}</td>
                    <td className="f-16 fw-400 tableText">{value.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
    );
}
