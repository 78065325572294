import InputBoxHorizontal from "../../../../Component/Merchant/Forms/InputBoxHorizontal";
// import CustomInputBox from "../../../../Component/Merchant/Forms/CustomInpuBox";

export const SettingTabs = [
  { id: 1, name: "Account Information" },
  { id: 2, name: "User Management" },
  { id: 3, name: "Available Billing Plans" },
  { id: 4, name: "FAQ" },
  { id: 5, name: "Feedback Management" },
];
export const InformationForm = [
  {tag:InputBoxHorizontal,label:"Name",type:"text",placeholder:"Enter your name",key_name:'name'},
  {tag:InputBoxHorizontal,label:"Role",type:"text",placeholder:"Enter your role",key_name:'name'},
  {tag:InputBoxHorizontal,label:"Industry",type:"text",placeholder:"Enter your industry",key_name:'name'},
  {tag:InputBoxHorizontal,label:"Mobile Number",type:"number",placeholder:"Enter your mobile number",key_name:'name'},
  {tag:InputBoxHorizontal,label:"Email Id",type:"text",placeholder:"Enter your email id",key_name:'name'},
  {tag:InputBoxHorizontal,label:"Current Password",type:"password",placeholder:"********",key_name:'name'},
  {tag:InputBoxHorizontal,label:"Address",type:"text",placeholder:"Enter your address",key_name:'name'},
]
export const Answers =[
  {name:'All Services are Best',key_name:''},
  {name:'All Services are Good',key_name:''},
  {name:'Some Specific services need to be change better',key_name:''},
  {name:'All Services need to change better',key_name:''},
  {name:'Price is too high',key_name:''},
  {name:'Need a better help Policy',key_name:''},
  {name:'All to be Services to be changed not a good experience',key_name:''},
]