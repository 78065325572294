import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import leftarrow from "../../../../assets/image/qrcode/arrow-left.svg";
import InputBox from "../../../../Component/Merchant/Forms/InputBox";
import RadioBtn from "../../../../Component/Merchant/RadioBtn";
import SubHead from "../../../../Component/Merchant/SubHead";
import circlecheck from "../../../../assets/image/qrcode/circle-check.svg";
import line from "../../../../assets/image/qrcode/Line.svg";
import linefull from "../../../../assets/image/qrcode/Line-Full.svg";
import phone from "../../../../assets/image/qrcode/Screenshot.svg";
function TemplateDetailQr() {
    return (
        <div>
            <div>
                <img src={phone} alt=""></img>
            </div>
            <div>
                <button className="btn-download btn-preview bgcolor-white">
                    <h1 className="DMSans-Regular f-18 fw-600 color-black">Preview Page View</h1>  
                </button>
            </div>        
        </div>
    );
}
export default TemplateDetailQr;
