import React, { FunctionComponent } from "react";
import Table from 'react-bootstrap/Table';

const allcode = [
    {
      username: "Stephen James",
      mailid: "stephen_james23@gmail.com",
      mobileno: "6789023467",
      codetype: "Static",
      codename: "Sample Video",
      ctatype: "Facebook",
      status: "Active"
    },
    {
        username: "Peter Hendry",
        mailid: "peterhendry5@yahoo.com",
        mobileno: "9909876541",
        codetype: "Dynamic",
        codename: "Test",
        ctatype: "Twitter",
        status: "Inactive"
    },
    {
        username: "Antony Brito",
        mailid: "antonybrito_8@gmail.com",
        mobileno: "7869048320",
        codetype: "Dynamic",
        codename: "Introduction video",
        ctatype: "LinkedIn",
        status: "Active"
    },
    {
        username: "Stephen James",
        mailid: "stephen_james23@gmail.com",
        mobileno: "6789023467",
        codetype: "Static",
        codename: "Sample Video",
        ctatype: "You tube",
        status: "Active"
    },
    {
        username: "Peter Hendry",
        mailid: "peterhendry5@yahoo.com",
        mobileno: "9909876541",
        codetype: "Dynamic",
        codename: "Test",
        ctatype: "Whatsapp",
        status: "Inactive"
    },
    {
        username: "Antony Brito",
        mailid: "antonybrito_8@gmail.com",
        mobileno: "7869048320",
        codetype: "Dynamic",
        codename: "Introduction video",
        ctatype: "Instagram",
        status: "Active"
    },
    {
        username: "Stephen James",
        mailid: "stephen_james23@gmail.com",
        mobileno: "6789023467",
        codetype: "Static",
        codename: "Sample Video",
        ctatype: "Facebook",
        status: "Active"
    },
    {
        username: "Peter Hendry",
        mailid: "peterhendry5@yahoo.com",
        mobileno: "9909876541",
        codetype: "Dynamic",
        codename: "Test",
        ctatype: "Instagram",
        status: "Inactive"
    },
    {
        username: "Antony Brito",
        mailid: "antonybrito_8@gmail.com",
        mobileno: "7869048320",
        codetype: "Dynamic",
        codename: "Introduction video",
        ctatype: "Twitter",
        status: "Active"
    },
  ];
  
  export default function AllList() {
    return (   
        <div className="custom-table mt-3">
          <Table>
            <thead>
              <tr className="f-14 fw-500 tableHead">
                <th>User Name</th>
                <th>Email Id</th>
                <th>Mobile Number</th>
                <th>Code Type</th>
                <th>Code Name</th>
                <th>CTA Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {allcode.map((value, key) => {
                return (
                  <tr key={key}>
                    <td className="f-16 fw-500 tableText">{value.username}</td>
                    <td className="f-16 fw-400 tableText">{value.mailid}</td>
                    <td className="f-16 fw-400 tableText">{value.mobileno}</td>
                    <td className="f-16 fw-400 tableText">{value.codetype}</td>
                    <td className="f-16 fw-400 tableText">{value.codename}</td>
                    <td className="f-16 fw-400 tableText">{value.ctatype}</td>
                    <td className="f-16 fw-400 tableText">{value.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
    );
}
  