import React, { useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { AiOutlineMinusCircle } from "react-icons/ai";
const Accordion = ({ sections }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      {sections.map((section, index) => (
        <div
          style={{
            cursor: "pointer",
            padding: "10px",
            borderBottom: "1px solid #ccc",
            marginBottom: "5px",
          }}
          key={index}
        >
          <div className="f-18 fw-400" onClick={() => handleToggle(index)}>
            {index === activeIndex ? (
              <AiOutlineMinusCircle className="f-24 me-2" />
            ) : (
              <FiPlusCircle className="f-24 me-2" />
            )}
            {section.title}
          </div>
          {index === activeIndex && (
            <div
              className="f-16 fw-400 color-emperor mt-2"
              style={{
                padding: "10px",
                borderLeft: "2.5px solid #5EB02B",
                transition: '350ms'
              }}
            >
              {section.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default Accordion;
