import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../../../Service/Axios";
import { getPosters, qr_code_design } from "../../../../Service/ApiUrls";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function Design(props) {
  const { id, setActiveId, formData, handleInputChange } = props;
  const [posters, setPosters] = useState([]);
  const [selectedPosterId, setSelectedPosterId] = useState(null); // Track selected poster ID
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const navigate = useNavigate();
  const BASE_URL = "https://linkoo.s3.amazonaws.com/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Start loader
        const res = await api.get(getPosters);
        setPosters(res?.data?.data?.posters);
        toast.success("Posters loaded successfully!");
      } catch (err) {
        toast.error("Error fetching posters.");
        console.error("Error fetching posters:", err);
      } finally {
        setIsLoading(false); // Stop loader
      }
    };
    fetchData();
  }, []);

  // Handle checkbox selection
  const handleCheckboxChange = (posterId) => {
    setSelectedPosterId(posterId); // Set the selected poster ID
    console.log("Selected Poster ID:", posterId);
  };

  const handleSubmit = async () => {
    if (!selectedPosterId) {
      toast.warning("Please select a poster before submitting.");
      return;
    }

    const data = {
      qrdesign_id: selectedPosterId,
      template_id: selectedPosterId,
    };
    console.log("Payload:", data);

    try {
      setIsLoading(true); // Start loader
      const response = await api.put(`${qr_code_design}${id}`, data);
      toast.success("QR code design created successfully!");
      navigate(-1);
    } catch (error) {
      toast.error("Error creating QR code design.");
      console.error("Error submitting QR code details:", error);
    } finally {
      setIsLoading(false); // Stop loader
    }
  };

  const handleCreate = async () => {
    setIsLoading(true); // Show loader
    try {
      // Simulate form submission or any asynchronous operation
      await handleSubmit();
      toast.success("QR Code details submitted successfully!")
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
      console.error("Error during form submission:", error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };
  return (
    <>
      <Row>
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
            <p>Loading...</p>
          </div>
        ) : (
          posters?.map((item) => {
            const fullImageUrl = `${BASE_URL}${item.poster_url}`;
            return (
              <Col md={4} key={item.id} className="text-center">
                <span>
                  <input
                    type="checkbox"
                    name="qrdesign_id"
                    value={item.id}
                    checked={selectedPosterId === item.id} // Control checkbox state
                    onChange={() => handleCheckboxChange(item.id)} // Handle selection
                  />
                  <div>
                    <img
                      src={fullImageUrl}
                      alt={`Poster ${item.id}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "300px",
                      }}
                      onError={(e) =>
                        (e.target.src = "fallback-image-url.png")
                      }
                    />
                  </div>
                </span>
              </Col>
            );
          })
        )}
      </Row>

      <br />
      <Row className="d-inline-flex">
        <Col className="d-flex justify-content-start">
          <button
            className="btn-download bgcolor-white"
            onClick={() => {
              setIsLoading(true); // Start loader
              setTimeout(() => {
                setActiveId(2);
                setIsLoading(false); // Stop loader
                toast.info("Navigated back successfully!");
              }, 500);
            }}
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <h1 className="DMSans-Regular f-18 fw-600 color-blak">Back</h1>
            )}
          </button>
        </Col>
        <Col className="d-flex justify-content-between">
          <button
            className="btn-download bgcolor-cerise"
            onClick={handleCreate}
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <h1 className="DMSans-Regular f-18 fw-600 color-white">
                Create
              </h1>
            )}
          </button>
        </Col>
      </Row>
    </>
  );
}

export default Design;
