import { createSlice } from "@reduxjs/toolkit";
import {
  SigninMerchant,
  SignoutMerchant,
} from "../../async-actions/Merchant/auth";

const initialState = {
  role: "",
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    // [SigninMerchant.pending]: (state) => {
    //   state.loading = true;
    // },
    // [SigninMerchant.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   var roles = JSON.stringify(action.payload?.available_permission, [
    //     "permission_name",
    //     "read",
    //     "delete",
    //     "edit",
    //     "write",
    //   ]);
    //   if (action.payload?.status) {
    //     state.error = action.payload?.status;
    //     state.token = "";
    //     state.role = "";
    //     localStorage.clear("merchantToken");
    //   } else {
    //     state.token = action.payload?.api_token;
    //     console.log(action.payload.platform_config);
    //     localStorage.setItem("merchantRole", roles);
    //     localStorage.setItem("merchantToken", action.payload.api_token);
    //     localStorage.setItem(
    //       "merchantPlatformPermission",
    //       action.payload?.platform_permissions
    //     );
    //     localStorage.setItem(
    //       "merchantPlatform",
    //       JSON.stringify(action.payload?.platform_config)
    //     );
    //     localStorage.setItem("merchantShopify", action.payload.enable_shopify);
    //     localStorage.setItem("pregeneratedCoupons", action.payload.allow_pregenerated_coupons);
    //     if (action.payload.platform_config) {
    //       localStorage.setItem("platformConfig", "yes");
    //     } else {
    //       localStorage.setItem("platformConfig", "no");
    //     }
    //     localStorage.setItem(
    //       "merchantSmartForm",
    //       action.payload.enable_smart_form
    //     );
    //     localStorage.setItem("merchantType", action.payload.merchant_type);
    //     state.error = "";
    //   }
    // },
    // [SigninMerchant.rejected]: (state) => {
    //   state.loading = true;
    //   state.error = 401;
    // },
    // [SignoutMerchant]: (state) => {
    //   state.token = "";
    //   state.role = "";
    //   localStorage.clear("merchantToken");
    //   localStorage.clear("merchantRole");
    //   localStorage.clear("merchantShopify");
    //   localStorage.clear("merchantSmartForm");
    //   localStorage.clear("merchantPlatformPermission");
    //   localStorage.clear("merchantPlatform");
    //   localStorage.clear("pregeneratedCoupons");
    // },
  },
});

export default authSlice.reducer;
