import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import leftarrow from "../../../../assets/image/qrcode/arrow-left.svg";
import qrcode from "../../../../assets/image/qrcode/qrcode.svg";
import star from "../../../../assets/image/qrcode/star.svg";
import play from "../../../../assets/image/qrcode/play.svg";
import link from "../../../../assets/image/qrcode/link.svg";
import copy from "../../../../assets/image/qrcode/copy.svg";
import SubHead from "../../../../Component/Merchant/SubHead";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import Dropdownpink from "../../../../Component/Merchant/Forms/Dropdownpink";
import { LiaCopy } from "react-icons/lia";
import { Link } from "react-router-dom";
function IndividualQRCodeAnalytics() {   
    return (
        <>
            <SubHead head="QR code" first="Home" second="QR Code" third="All Codes" fourth="Sample QR Code Test Code"/> 
            <br/>
            <div className="left-arrow">
                <h3 className="color-black DMSans-Regular f-18 fw-400"><img src={leftarrow} alt="leftarrow"/><Link to ="/qrcode">Sample QR Code Test Code</Link></h3>    
            </div>

            <Row className="mt-3">
                <Col xs={12} md={8} lg={8}>
                    <div className="individualqr-layout">
                        
                            <div className="text-update">
                                <p className="color-emperor Inter-Regular f-12 fw-400">Updated on 12, Dec 2023 05:00 Pm</p>    
                            </div>
                            <div className="billing-plan bgcolor-snowflurry">
                                <small className="Inter-Regular f-12 fw-600 color-olivedrab">
                                    Active
                                </small>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <img className="qr-layout bgcolor-white" src={qrcode} alt="scanme"/>
                                </div>
                                <div className="text-sample">
                                    <h3 className="color-black DMSans-Regular f-18 fw-600"> Sample QR Code Test code<span>{'  '}<img src={star} alt="star"/></span></h3>
                                    <h3 className="color-mandalay DMSans-Regular f-14 fw-400"><img src={play} alt="play"/>20 Scans</h3>
                                    <h3 className="color-emperor DMSans-Regular f-14 fw-400">Lorem ipsum dolor sit amet, consectur adipiscing elit, sed do eiusmod tempor incididunt.</h3>
                                    <h3 className="color-emperor DMSans-Regular f-14 fw-400">Chennai</h3>
                                </div>
                                <Dropdownpink dummy="Download" />                                
                            </div>
                            <h3 className="color-emperor DMSans-Regular f-14 fw-400 text-update"><img src={link} alt="link"/>linkoo.web/34meiprs.com{' '}<LiaCopy /></h3>            
                    </div>
                </Col>
            </Row>
            <br/>
            <div>
                <Row>
                    <Col md={12} lg={8}>
                        <Row>
                            <Col>
                                Period
                                <br/><br/><Dropdown dummy="Last 7 Days/Last Week" />
                            </Col>
                            <Col>
                                Countries
                                <br/><br/><Dropdown dummy="All" />
                            </Col>
                            <Col>
                                Cities
                                <br/><br/><Dropdown dummy="All" />
                            </Col>
                            <Col>
                                Devices
                                <br/><br/><Dropdown dummy="All" />
                            </Col>
                            <Col>
                                <button className=" btn-export bgcolor-white">
                                    <h3 className="color-black DMSans-Regular f-18 fw-600">
                                        Export Information
                                    </h3>
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>    
            <Row className="mt-3 mb-3">
                <Col className="mb-3" md={12} lg={9}>
                    <Row>
                        <Col xs={12} md={6} lg={3}>
                            <div className="box-individual bgcolor-snowflurry">
                                <div className="p-3">
                                    <h5 className="DMSans-Regular f-48 fw-500 color-olivedrab">Dynamic </h5>
                                </div>
                                <div className="box-half bgcolor-white">
                                    <h5 className="DMSans-Regular f-18 fw-500 color-black">QR Code Category</h5>
                                </div>
                            </div>                    
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className="box-individual bgcolor-pinklace">
                                <div className="p-3">
                                    <h5 className="DMSans-Regular f-48 fw-500 color-cerise">80</h5>
                                </div>
                                <div className="box-half bgcolor-white">
                                    <h5 className="DMSans-Regular f-18 fw-500 color-black">Total Scans</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className="box-individual bgcolor-sandybeach">
                                <div className="p-3">
                                    <h5 className="DMSans-Regular f-48 fw-500 color-mandalay">47 </h5>
                                </div>
                                <div className="box-half bgcolor-white">
                                    <h5 className="DMSans-Regular f-18 fw-500 color-black">Unique Scans</h5>
                                </div>                          
                            </div>                    
                        </Col>
                        <Col xs={12} md={6} lg={3}>                
                            <div className="box-individual bgcolor-bluechalk">
                                <div className="p-3">
                                    <h5 className="DMSans-Regular f-48 fw-500 color-purpleheart">65</h5>
                                </div>
                                <div className="box-half bgcolor-white">
                                    <h5 className="DMSans-Regular f-18 fw-500 color-black">No.of impression</h5>
                                </div>
                            </div>
                        </Col>
                    </Row> 
                </Col>
            </Row>
            
            <div className="performance-layout bgcolor-white">
                <h5 className="DMSans-Regular f-24 fw-500 color-black">Link Performance by Date</h5>
                <Row>
                    <Col xs={12} md={6} lg={6}>
                        
                            <h5 className="DMSans-Regular f-18 fw-500">Total Scans </h5>
                        
                    </Col>
                    <Col xs={12} md={6} lg={6}>        
                        
                            <h5 className="DMSans-Regular f-18 fw-500">Unique Scans</h5>
                        
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default IndividualQRCodeAnalytics;