import React, { useState } from 'react';
// import "../../../../assets/Css/MobileFrame.css";
import MobileFrame from './MobileFrame';

const TemplateQr = ({
    templateId,
    headerContent,
    description,
    ctaOption,
    ctaOptions,
    selectedSocialMedia
}) => {
    const templateImage = `https://linkoo.s3.amazonaws.com/${templateId}`;

    const selectedCta = ctaOptions.find((cta) => cta.id === ctaOption);
    const ctaName = selectedCta ? selectedCta.cta_category_name : "";

    // State to handle input field data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        date: '',
        time: '',
    });

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Form JSX
    const formContent = ctaOption === 2 ? (
        <div className="form-container">
            <form>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className='input'
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Enter your name"
                    />
                </div>
                <br />
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className='input'
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Enter your email"
                    />
                </div>
                <br />
                <div className="form-group">
                    <label htmlFor="mobile">Mobile:</label>
                    <input
                        type="tel"
                        id="mobile"
                        className='input'
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        placeholder="Enter your mobile number"
                    />
                </div>
                <br />
                <div className="form-group">
                    <label htmlFor="date">Date:</label>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        className='input'
                        value={formData.date}
                        onChange={handleInputChange}
                    />
                </div>
                <br />
                <div className="form-group">
                    <label htmlFor="time">Time:</label>
                    <input
                        type="time"
                        id="time"
                        name="time"
                        className='input'
                        value={formData.time}
                        onChange={handleInputChange}
                    />
                </div>
                <br />
                <button type="submit" className='button btn-primarry'>Submit</button>
            </form>
        </div>
    ) : null;

    return (
        <MobileFrame>
            <div
                className="template-qr-container"
                style={{ backgroundImage: `url(${templateImage})` }}
            >
                <div className="header-content">
                    <h2>{headerContent}</h2>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                <div className="footer-content">
                    <div className='description'>
                        <p>{description}</p>

                    </div>
                    <div className='socialMedia'>
                        {ctaOption === 1 && selectedSocialMedia && (
                            <div>

                                <p>{selectedSocialMedia}</p>
                            </div>
                        )}
                    </div>

                    <div className="form">
                {ctaOption === 2 && (
                        <div>
                            {formContent}
                        </div>
                    )}
                </div>
                </div>
               
            </div>
        </MobileFrame>
    );
};

export default TemplateQr;
