import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
// import leftarrow from "../../../../assets/image/qrcode/arrow-left.svg";
// import qrcode from "../../../../assets/image/qrcode/qrcode.svg";
// import star from "../../../../assets/image/qrcode/star.svg";
// import play from "../../../../assets/image/qrcode/play.svg";
// import link from "../../../../assets/image/qrcode/link.svg";
// import copy from "../../../../assets/image/qrcode/copy.svg";
import QRChart from "../../../../Component/Merchant/Charts/QRChart";
import LineChart from "../../../../Component/Merchant/Charts/LineChart";
import SubHead from "../../../../Component/Merchant/SubHead";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import createIcon from "../../../../assets/image/Add.svg";
import { useState } from "react";

function QRCodeAnalytics() {
  
  const [activeStatus, setActiveStatus] = useState();

  const [period, setPeriod] = useState([
    {label: 'Last 7 Days/Last Week', value: 0},
    {label: 'Last Month', value: 1},
    {label: 'Last Year', value: 2}
  ]);

  const [codeTitle, setCodeTitle] = useState([
    {label: 'Code Title', value: 0},
    {label: 'All', value: 1},
  ]);
  const [location, setLocation] = useState([
    {label: 'Location', value: 0},
    {label: 'Chennai', value: 1},
    {label: 'Bengaluru', value: 2},
    {label: 'Hyderabad', value: 2},
    {label: 'Mumbai', value: 2},
    {label: 'Delhi', value: 2},
    {label: 'Trivandrum', value: 2},
  ]);

  const handleChange = (event) => {
    setActiveStatus(event.target.value);
  };

  return (
    <>
      <React.Fragment>
        <br />
      </React.Fragment>
      <Row>
        <Col className="Qr-Analysis" md={12} lg={8}>
          <Row>
            <Col>
              <p className="f-18 fw-500 mb-0">Period</p>
              <Dropdown list={ period } onhandlechange={handleChange}/>
            </Col>
            <Col>
              <p className="f-18 fw-500 mb-0">QR Code title</p>
              <Dropdown list={ codeTitle } onhandlechange={handleChange} />
            </Col>
            <Col>
              <p className="f-18 fw-500 mb-0">Location</p>
              <Dropdown list={ location } onhandlechange={handleChange} />
            </Col>
            <Col>
              <button className=" btn-export bgcolor-white">
                <h3 className="color-black DMSans-Regular f-16 fw-600" style={{padding: "3px 10px"}}>
                  Export Information
                </h3>
              </button>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col className="p-3 mb-3" md={12} lg={6}>
              <QRChart element={<LineChart />} title="Overall QR Analysis" />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default QRCodeAnalytics;
