import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import ProgressBar from "../../../Component/Merchant/ProgressBar";

function BillingTiles() {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const [showFullText, setShowFullText] = useState(false);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <Row className="mt-3">
      <Col xs={12} md={4} lg={4}>
        <div className="box_billing">
          <div className="billing-plan bgcolor-snowflurry">
            <small className="Inter-Regular f-12 fw-600 color-olivedrab">
              Current Plan
            </small>
          </div>
          <div className="d-flex justify-content-between border-bottom">
            <div>
              <h5 className="f-16 fw-600 DMSans-Regular color-black mt-4">
                {" "}
                Professional Plan
              </h5>
              <h5 className="f-14 fw-400 Inter-Regular color-black">
                Next Renewal on Jan 10, 2024
              </h5>
            </div>
            <div className="mt-4">
              {" "}
              <span className="DMSans-Regular color-black f-24 fw-600">
                $ 50
                <span className="Inter-Regular f-16 fw-400">/per month</span>
              </span>
            </div>
          </div>
          <div>
            <div>
              <div className="d-flex justify-content-between mt-4">
                <small className="f-16 fw-600 DMSans-Regular color-black">
                  {" "}
                  Plan Benefits
                </small>
                <div className="position-relative">
                  <BsThreeDotsVertical
                    className="cursor"
                    onClick={toggleOptions}
                  />
                  {showOptions && (
                    <div className="options-container cursor position-absolute" style={{ background: "#ffffff", padding: "8px", marginTop: "5px"}}>
                      <h5
                        className="f-14 fw-400 Inter-Regular color-black"
                        onClick={() => {
                          console.log("Update clicked");
                          setShowOptions(false);
                        }}
                      >
                        Update
                      </h5>
                      <h5
                        className="f-14 fw-400 Inter-Regular color-black"
                        onClick={() => {
                          console.log("Cancel clicked");
                          setShowOptions(false);
                        }}
                      >
                        Cancel
                      </h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h5 className="f-14 fw-400 Inter-Regular color-black">
              Our Features we offers in Professional Plan
            </h5>
            <h5 className="f-14 fw-400 Inter-Regular color-black">
            {showFullText ? (
          <>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
            occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.{" "}
            <span onClick={toggleFullText} style={{ cursor: "pointer" }}>
              <b>read less...</b>
            </span>
          </>
        ) : (
          <>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt {" "}
            <span onClick={toggleFullText} style={{ cursor: "pointer" }}>
              <b>read more...</b>
            </span>
          </>
        )}
            </h5>
          </div>
        </div>
      </Col>
      <Col xs={12} md={4} lg={4}>
        <div className="box_billing">
          <small className="f-16 fw-600 DMSans-Regular color-black">
            File Storage
          </small>
          <div className="progress-box mt-3 ">
            <ProgressBar />
          </div>
          <div className="mt-2">
            {" "}
            <span className="DMSans-Regular color-black f-24 fw-600">
              $ 50
              <span className="Inter-Regular f-16 fw-400">/per month</span>
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default BillingTiles;
