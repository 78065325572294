import { Button } from "@mui/material";
import React from "react";
import { Table } from "react-bootstrap";
import file from "../../../assets/image/file.svg";
import * as XLSX from "xlsx";

function BillingTable() {
  const tableData = [
    {
      invoice: "Invoice #987",
      billingDate: "Dec 2, 2023",
      amount: "$ 50/per month",
      plan: "Professional Plan",
      status: "Paid",
    },
    {
      invoice: "Invoice #988",
      billingDate: "Dec 2, 2023",
      amount: "$ 50/per month",
      plan: "Professional Plan",
      status: "Draft",
    },
    {
      invoice: "Invoice #989",
      billingDate: "Dec 2, 2023",
      amount: "$ 50/per month",
      plan: "Professional Plan",
      status: "Inactive",
    },
  ];

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Billing History");
    XLSX.writeFile(workbook, "Billing_History.xlsx");
  };

  const downloadSingleRowExcel = (rowData) => {
    // Transform the single row into an array for exporting
    const transformedData = [rowData];
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Single Invoice");
    XLSX.writeFile(workbook, `${rowData.invoice.replace("#", "")}.xlsx`);
  };

  return (
    <div className="box_billing mt-4">
      <div className="d-flex justify-content-between">
        <div>
          <small className="f-16 fw-600 DMSans-Regular color-black">
            Billing History{" "}
          </small>
          <h5 className="f-14 fw-400 Inter-Regular color-black">
            Download your Previous plans invoice and Usage Details
          </h5>
        </div>
        <div>
          <Button
            variant="contained"
            className="btn-download bgcolor-cerise"
            onClick={downloadExcel}
          >
            Download all
          </Button>
        </div>
      </div>
      <div className="custom-table mt-3">
        <Table>
          <thead>
            <tr className="f-14 fw-500 tableHead">
              <th>Invoice</th>
              <th>Billing Date</th>
              <th>Amount</th>
              <th>Plan</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td className="f-16 fw-500 tableText">{row.invoice}</td>
                <td className="f-16 fw-400 tableText">{row.billingDate}</td>
                <td className="f-16 fw-400 tableText">{row.amount}</td>
                <td className="f-16 fw-400 tableText">{row.plan}</td>
                <td className="f-16 fw-400 tableText">
                  <small
                    className={
                      row.status === "Paid"
                        ? "status-active"
                        : row.status === "Draft"
                        ? "status-drafted"
                        : "status-inactive"
                    }
                  >
                    {row.status}
                  </small>
                </td>
                <td>
                  <img
                    src={file}
                    alt="Download Row"
                    className="mt-3 cursor"
                    onClick={() => downloadSingleRowExcel(row)}
                    style={{ cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default BillingTable;
