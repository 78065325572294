import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsGraphUpArrow } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlineColorLens } from "react-icons/md";
import { LiaCopy } from "react-icons/lia";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegShareSquare } from "react-icons/fa";
import { PiFolderSimpleThin } from "react-icons/pi";
import { PiStarLight } from "react-icons/pi";

function Action(props) {
  const { link, QrCode } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="option">
      <div><p className="mb-0">Options</p></div>
      <div>
        <BsThreeDotsVertical
          className="cursor"
          onClick={handleClick}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem><BsGraphUpArrow />QR Analysis</MenuItem>
          <MenuItem><FiEdit3 />Edit Content</MenuItem>
          <MenuItem><MdOutlineColorLens />Edit QR Design</MenuItem>
          <MenuItem><LiaCopy />Make a copy</MenuItem>
          <MenuItem><RiDeleteBinLine />Delete</MenuItem>
          <MenuItem><FaRegShareSquare />Share this QR</MenuItem>
          <MenuItem><PiFolderSimpleThin />Move to Folder</MenuItem>
          <MenuItem><PiStarLight />Add to Favorite</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
export default React.memo(Action);