import React from "react";
import ChevronRight from "../../assets/image/dashboard/chevron-right.svg";
function SubHead(props) {
  const { head,first, second, third,fourth } = props;
  return (
    <div className="d-flex justify-content-between mb-3">
      <div>
        <p className="mb-0 f-24 fw-600 DMSans-Regular">{head}</p>
      </div>
      <div className="mb-0 fw-400 Inter-Regular"><img src={ChevronRight} alt="i" /><small> &nbsp;
      {first}
      {second !== undefined ? (
        <>
           &nbsp;{'>'}&nbsp;
          {second}
        </>
      ) : (
        ""
      )}
      {third !== undefined ? (
        <>
           &nbsp;{'>'}&nbsp;
          {third}
        </>
      ) : (
        ""
      )}
      {fourth !== undefined ? (
        <>
           &nbsp;{'>'}&nbsp;
          {fourth}
        </>
      ) : (
        ""
      )}</small></div>
    </div>
  );
}

export default SubHead;
