// import React, { FunctionComponent } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   LabelList,
// } from "recharts";

// const data = [
//   {
//     name: "Week 1",
//     uv: 100,
//     pv: 0,
//   },
//   {
//     name: "Week 2",
//     uv: 300,
//     pv: 198,
//   },
//   {
//     name: "Week 3",
//     uv: 200,
//     pv: 300,
//   },
//   {
//     name: "Week 4",
//     uv: 280,
//     pv: 308,
//   },
//   {
//     name: "Week 5",
//     uv: 180,
//     pv: 400,
//   },
//   {
//     name: "Week 6",
//     uv: 290,
//     pv: 300,
//   },
//   {
//     name: "Week 7",
//     uv: 490,
//     pv: 400,
//   },
// ];

// const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
//   const { x, y, payload } = props;

//   return (
//     <g transform={`translate(${x},${y})`}>
//       {/* Add a circle marker for every indicator */}
//       <circle cx={0} cy={-8} r={4} fill="#CBCBCB" />
//       <text x={0} y={0} dy={16} textAnchor="middle" fontSize={10} fill="#666">
//         {payload.value}
//       </text>
//     </g>
//   );
// };

// export default function Chart() {
//   return (
//     <LineChart
//       width={300}
//       height={100}
//       data={data}
//       margin={{
//         top: 10,
//         right: 30,
//         left: -20,
//         bottom: 10,
//       }}
//       >
//       <CartesianGrid />
//       <XAxis
//         dataKey="name"
//         height={10}
//         stroke="grey"
//         // tick={<CustomizedAxisTick />}
//       />
//       <YAxis 
//         axisLine={false} 
//         fontSize={10} 
//         allowDataOverflow={true} 
        
//       />
//       <Tooltip />
//       {/* <Legend /> */}
//       {/* <Line
//         type="monotone"
//         dataKey="pv"
//         stroke="#5EB02B"
//         strokeWidth="2px"
//         dot={false}
//       /> */}
//       <Line
//         type="monotone"
//         dataKey="uv"
//         stroke="#D235C3"
//         strokeWidth="1px"
//         dot={false}
//       />
//     </LineChart>
//   );
// }


import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const data = [
  {
    name: "Week 1",
    uv: 10,
  },
  {
    name: "Week 2",
    uv: 25,
  },
  {
    name: "Week 3",
    uv: 20,
  },
  {
    name: "Week 4",
    uv: 40,
  },
  {
    name: "Week 5",
    uv: 18,
  },
  {
    name: "Week 6",
    uv: 25,
  },
  {
    name: "Week 7",
    uv: 30,
  }
];

export default function App() {
  return (
    <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="2 2" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      {/* <Legend /> */}
      {/* <Line
        type="monotone"
        dataKey="pv"
        stroke="#5EB02B"
        activeDot={{ r: 8 }}
      /> */}
      <Line type="monotone" dataKey="uv" stroke="#d235c3" strokeWidth="2px" />
    </LineChart>
  );
}