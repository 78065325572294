import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { social_media_types } from "../../../../../Service/ApiUrls";
import api from "../../../../../Service/Axios";

const SocialMedia = ({ formData, handleInputChange }) => {
  const [socialLinks, setSocialLinks] = useState([]);
  const [socialMediaOptions, setSocialMediaOptions] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [customLink, setCustomLink] = useState("");
  const [selectedSocialMedia, setSelectedSocialMedia] = useState(""); // Track selected social media type
  const [id, setId] = useState("")

  const handleAddSocialLink = () => {
    if (selectedPlatform && customLink) {
      // Find the selected platform name
      const selectedOption = socialMediaOptions.find(option => option.id === selectedPlatform);
      const newLinks = [
        ...socialLinks,
        {
          social_media_id: selectedPlatform,
          social_media_name: selectedOption ? selectedOption.social_media_name : "", // Store the name
          social_media_link: customLink,
        },
      ];
      setSocialLinks(newLinks);
      setSelectedPlatform("");
      setCustomLink("");
      const event = {
        target: {
          name: "social_media_links",
          value: newLinks,
        },
      };
      handleInputChange(event);
    }
  };

  const handleDeleteLink = (index) => {
    const updatedLinks = socialLinks.filter((_, i) => i !== index);
    setSocialLinks(updatedLinks);
    const event = {
      target: {
        name: "social_media_links",
        value: updatedLinks,
      },
    };
    handleInputChange(event);
  };

  const handleSocialMediaChange = (event) => {
    setSelectedSocialMedia(event.target.value); // Update the selected social media type
    handleInputChange(event); // Notify the parent component
    setId(event.target.value)
  };

  useEffect(() => {
    if(id) {
      let socialName = socialMediaOptions.filter(a => a.id == id)[0].social_media_name
      console.log("social name", socialName)
      let event = {target : {name: "socialName", value: socialName}}
      handleInputChange(event)
    }
  }, [id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(social_media_types);
        setSocialMediaOptions(res?.data?.data?.social_media_types);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px" }}
    >
      <Row className="mb-3">
        <Form.Label htmlFor="customLink" className="form-label-h">
          Custom Social Link
        </Form.Label>
        <Col md={4}>
          <Form.Select
            aria-label="Select Social Media Platform"
            value={selectedSocialMedia}

            // value={selectedPlatform}
            // onChange={(e) => setSelectedPlatform(e.target.value)}
            name="social_media_name"
            onChange={handleSocialMediaChange} // Update on change

          >
            <option value="" disabled>
              Select Social Links
            </option>
            {socialMediaOptions.map((item, index) => (
              <option key={index} value={item.id}>
                {item.social_media_name}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter Custom Link"
            value={customLink}
            onChange={(e) => setCustomLink(e.target.value)}
            name="social_media_link"
          />
        </Col>

        <Col md={2}>
          <Button
            className="ms-2"
            variant="contained"
            onClick={handleAddSocialLink}
          >
            Add Link
          </Button>
        </Col>
      </Row>
      {socialLinks.length > 0 && (
        <Card className="mt-4">
          <Card.Header>
            Added Social Links (
            {formData?.cta_category_id || "No Category Selected"})
          </Card.Header>
          <Card.Body>
            {socialLinks.map((link, index) => (
              <Row key={index} className="mb-2">
                <Col md={4}>
                  <span className="social-platform">
                    {link.social_media_name} {/* Display the selected platform name */}
                  </span>
                </Col>
                <Col>
                  <input
                    type="text"
                    className="form-control"
                    value={link.social_media_link}
                    readOnly // Makes the input field read-only
                  />
                </Col>
                <Col md={2}>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => handleDeleteLink(index)}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            ))}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default SocialMedia;