import * as React from "react";

function TextArea(props) {
  const { label, placeholder, handle, key_name, disab, require, maxLength, onChange } =
    props;

  return (
    <div>
      <label className="input-label">{label}</label>
      <textarea
        className="input-style-area"
        aria-label="Demo input"
        rows={4}
        placeholder={placeholder}
        value={props[key_name]}
        name={key_name}
        onChange={onChange}
        disabled={disab}
      />
    </div>
  );
}
export default React.memo(TextArea);
