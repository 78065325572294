import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import InputBox from "../../../../Component/Merchant/Forms/InputBox";

const CreateUser = (props) => {
  const { show, setShow } = props;
  const [status, setStatus] = useState([
    { label: "Active", value: 0 },
    { label: "Inactive", value: 1 },
  ]);

  const handleClose = () => setShow(false);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="f-14 DMSans-Regular fw-600 color-black">Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <label className="color-black">User Name</label>
          <br />
          <InputBox
            type="text"
            placeholder="User Name"
            key_name="name"
            
          />
          <label className="color-black">Email Id</label>
          <br />
          <InputBox
            type="text"
            placeholder="Email Id"
            key_name="email"
            
          />
          <label className="color-black">Role</label>
          <br />
          <InputBox
            type="text"
            placeholder="Role"
            key_name="role"
            
          />
          <label className="mt-2">Status</label>
          <br />
          <div className="mt-2">
            <Dropdown list={status} onhandleChange={handleChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-bg-color" onClick={()=>setShow(false)}>
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateUser;
