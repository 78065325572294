import { Col, Row } from "react-bootstrap";
import Table from "./SocialMedia/SocialMediaTable";
import SocialMedia from "./SocialMedia";
import Appointments from "./Appointment";
import Feedback from "./Feedback";
import Forms from "./Forms";
import Events from "./Events";
import Gamification from "./Gamification";
import SubHead from "../../../Component/Merchant/SubHead";
import Tabs from "../../../Component/Merchant/Tabs";
import { StatisticsTabs } from "./Utils/utils";
import { useState } from "react";
import Warranty from "./Warranty";

function Statistics() {
  const [activeId, setActiveId] = useState(1);

  const getSubHeadThird = () => {
    switch (activeId) {
      case 1:
        return "Social Media";
      case 2:
        return "Appoinments";
      case 3:
        return "Feedback Survey";
      case 4:
        return "Events";
      case 5:
        return "Forms";
      case 6:
        return "Gamification";
      case 7:
        return "Warranty Module";
      default:
        return "";
    }
  };


  return (
    <>
      <SubHead head="Statistics" first="Home" second="Statistics" third={getSubHeadThird()} />
      <Row className="mt-2">
        <Col md={12} lg={8}>
          <Tabs
            values={StatisticsTabs}
            activeId={activeId}
            setActiveId={setActiveId}
          />{" "}
        </Col>
      </Row>
      {activeId === 1 && <SocialMedia />}
      {activeId === 2 && <Appointments />}
      {activeId === 3 && <Feedback />}
      {activeId === 4 && <Events />}
      {activeId === 5 && <Forms />}
      {activeId === 6 && <Gamification />}
      {activeId === 7 && <Warranty />}
    </>
  );
}
export default Statistics;