import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import SearchBox from "../../../../Component/Merchant/SearchBox";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import createIcon from "../../../../assets/image/Add.svg";
import UserTable from "./UserTable";
import CreateUser from "./CreateUser";

function UserManagement() {
  const [show, setShow] = useState(false);


  const handleShow = () => setShow(true);
  const [status, setStatus] = useState([
    {label: 'Status', value: 0},
    {label: 'Active', value: 1},
    {label: 'Inactive', value: 2}
  ]);

  return (
    <div>
      <Row>
        <Col md={6} lg={4}>
          <SearchBox />
        </Col>
        <Col md={12} lg={8}>
          <div className="d-flex justify-content-between">
            <Dropdown list={status} />
            <div className="create"             onClick={handleShow}
>
              <img src={createIcon} alt="" />
              <p className="mt-3"><b>Add User</b></p>
            </div>
          </div>
        </Col>
      </Row>
      <UserTable />
      <CreateUser show={show} setShow={setShow} />

    </div>
  );
}

export default UserManagement;
