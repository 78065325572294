import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Card, Col, Form, Row } from "react-bootstrap";

const Feedback = ({ handleInputChange }) => {
  const [feedbackData, setFeedbackData] = useState({
    cta_name: "",
    end_screen_message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const { cta_name, end_screen_message } = feedbackData;
    if (cta_name && end_screen_message) {
      // Trigger parent function to handle the data
      const event = {
        target: {
          name: "feedbacks",
          value: [feedbackData], // Pass data in the required format
        },
      };
      handleInputChange(event);
    }
  }, [feedbackData]);

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}
    >
      <Row className="mb-3">
        <Form.Label htmlFor="ctaName" className="form-label-h">
          Feedback Details
        </Form.Label>
        <Col md={4}>
          <Form.Control
            type="text"
            placeholder="Enter CTA Name"
            value={feedbackData.cta_name}
            name="cta_name"
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Enter Description"
            value={feedbackData.end_screen_message}
            name="end_screen_message"
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Display feedback data in a card (optional, can be removed if not needed) */}
      {feedbackData.cta_name && feedbackData.end_screen_message && (
        <Card className="mt-4">
          <Card.Header>Added Feedback Details</Card.Header>
          <Card.Body>
            <Row className="mb-2">
              <Col md={4}>
                <strong>CTA Name:</strong> {feedbackData.cta_name}
              </Col>
              <Col>
                <strong>Description:</strong> {feedbackData.end_screen_message}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Feedback;
