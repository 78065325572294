import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "../../../service/Merchant/axios";

export const clearMerchantSetting = createAction('clearMerchantsetting')

export const MerchantOverlay = createAsyncThunk(
  `merchant/overlay`,
  async (data) => {
    const response = data
    return await response;
  }
);
