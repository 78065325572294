import React from "react";

// This component handles the validation logic for the form inputs
const Validation = ({ credentials, touched, setError }) => {
  const validateEmail = (email) => {
    // Improved email regex: It requires letters, numbers, and the domain structure with @ and .
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    // Password validation: at least 6 characters
    return password.length >= 6;
  };

  React.useEffect(() => {
    // Email validation with required check
    if (touched.email_id) {
      if (!credentials.email_id) {
        setError((prevError) => ({
          ...prevError,
          email_id: "Email is required.",
        }));
      } else if (!validateEmail(credentials.email_id)) {
        setError((prevError) => ({
          ...prevError,
          email_id: "Please enter a valid email address (letters, numbers, and a proper domain).",
        }));
      } else {
        setError((prevError) => ({ ...prevError, email_id: "" }));
      }
    }

    // Password validation with required check
    if (touched.password) {
      if (!credentials.password) {
        setError((prevError) => ({
          ...prevError,
          password: "Password is required.",
        }));
      } else if (!validatePassword(credentials.password)) {
        setError((prevError) => ({
          ...prevError,
          password: "Password must be at least 6 characters.",
        }));
      } else {
        setError((prevError) => ({ ...prevError, password: "" }));
      }
    }
  }, [credentials, touched, setError]);

  return null;
};

export default Validation;
