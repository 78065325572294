import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import SubMenu from "./SubMenu";
import Navbar from "./Navbar";
import { MdLogout } from "react-icons/md";
import logo from "../assets/image/Logo2.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import logw from "../assets/image/Merchant/menu/logout_w.svg";
const SidebarNavLink = styled.span`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
`;
const SidebarNav = styled.nav`
  width: ${({ shotbar }) =>
    shotbar
      ? ({ sidebar }) => (sidebar ? "105px" : "70px")
      : ({ sidebar }) => (sidebar ? "105px" : "0px")};
  height: 100%;
  justify-content: end;
  position: fixed;
  display: flex;
  // left: ${({ shortbar }) => (shortbar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  overflow-y: auto;
`;
const SidebarLabel = styled.span`
  margin-left: 16px;
  width: 240px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: #ff505b;
  font-family: Gilroy-semibold;
`;
const SidebarWrap = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;
function Sidebar({ data }) {
  const [sidebar, setSidebar] = useState(true);
  const [shotbar, setshotbar] = useState(true);
  const [isloading, setloading] = useState(true);
  const showSidebar = () => setSidebar(!sidebar);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = [localStorage.getItem("role")].join().split(",");
  const permission = JSON.parse(localStorage.getItem("merchantRole"));
  // const overlay = useSelector((state) => state.setting.overlay);
  // const filteredArray = permission?.filter((obj) => obj.read === 1);
  // const MerchantRole = filteredArray?.map(
  //   ({ permission_name }) => permission_name
  // );
  let autoResize = () => {
    if (window.innerWidth <= 790) {
      setSidebar(false);
      setshotbar(false);
    } else {
      setSidebar(true);
      setshotbar(true);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", autoResize);
    autoResize();
  }, []);
  const Logout = () => {
    // dispatch(Signout());
    // dispatch(SignoutMerchant());
    navigate("login", { replace: true });
  };
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 1000);
  }, []);
  const path = window.location.pathname;
  return (
    <React.Fragment>
      {isloading ? (
        <Loader />
      ) : (
        <div className="layout-wrapper layout-content-navbar">
          {/* {overlay && (
            <div className="loader-overlay">
              <div className="loader-ove"></div>
            </div>
          )} */}
          <div className="layout-container">
            <SidebarNav
              className="layout-menu menu-vertical bg-menu-theme"
              sidebar={sidebar}
              shotbar={shotbar}
            >
              <SidebarWrap className="sidebar-wrap">
                <div className="logo-box mt-0">
                  <div className="logo">
                    <img
                      src={logo}
                      alt="logo"
                      className="cursor"
                      onClick={showSidebar}
                    />
                  </div>
                </div>
                <ul className="menu-inner py-1">
                  {data.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <li className="menu-item mt-3">
                          <SubMenu item={item} sidebar={sidebar} />
                        </li>
                      </React.Fragment>
                    );
                  })}
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li className="foot">
                    <SidebarNavLink>
                      {sidebar ? (
                        <div
                          className="d-flex justify-content-center cursur"
                          onClick={Logout}
                        >
                          <div className="logo">
                            <img src={logw} alt="logo" />
                          </div>
                        </div>
                      ) : (
                        <div className="cursur" onClick={Logout}>
                          <MdLogout
                            className="side-icon-log"
                            style={{ marginLeft: "-7px" }}
                          />
                        </div>
                      )}
                    </SidebarNavLink>
                  </li>
                </ul>
              </SidebarWrap>
            </SidebarNav>
            <div
              className={
                sidebar
                  ? shotbar
                    ? "layout-page"
                    : "layout-pagii"
                  : shotbar
                  ? "layout-pag"
                  : "layout-page"
              }
            >
              <Container fluid>
                <Container fluid style={{ marginTop: "1.5rem" }}>
                  <Navbar
                    width={sidebar ? "header-half" : "header-full"}
                    showSidebar={showSidebar}
                  />
                  <div className="content-wrapper mt-4 mb-3">
                    <Outlet />
                  </div>
                </Container>
              </Container>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Sidebar;
