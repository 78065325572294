// import * as React from 'react';
// import { LineChart } from '@mui/x-charts/LineChart';

// export default function BasicArea() {
//   return (
//     <LineChart
//       xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
//       series={[
//         {
//           data: [2, 5.5, 2, 8.5, 1.5, 5],
//           area: true,
//         },
//       ]}
//       width={500}
//       height={300}
//     />
//   );
// }


// import React from "react";
// import { Line } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler // 1. Import Filler plugin
// } from "chart.js";

// import faker from "faker";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler // 1. Register Filler plugin
// );

// export const options = {
//   responsive: true,
//   tension: 0.3 // 2. Set the tension (curvature) of the line to your liking.  (You may want to lower this a smidge.)
// };

// const labels = ["January", "February", "March", "April", "May", "June", "July"];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 0, 0)",
//       fill: {
//         target: "origin", // 3. Set the fill options
//         above: "rgba(255, 0, 0, 0.3)"
//       }
//     },
//     {
//       label: "Dataset 2",
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 500 })),
//       borderColor: "rgb(53, 162, 235)",
//       backgroundColor: "rgba(53, 162, 235, 0.3)",
//       fill: "origin" // 3. Set the fill options
//     }
//   ]
// };

// export function App() {
//   return <Line options={options} data={data} />;
// }


// import React from "react";
// import {
//    AreaChart,
//    Area,
//    YAxis,
//    XAxis,
//    CartesianGrid,
//    Tooltip,
//    Legend
//    } from "recharts";
//    class AreaRechartComponent extends React.Component {
//       data = [
//          {
//             name: "Jan 2019",
//             "Product A": 3432,
//             "Procuct B": 2342
//          },
//          {
//             name: "Feb 2019",
//             "Product A": 2342,
//             "Procuct B": 7746
//          },
//          {
//             name: "Mar 2019",
//             "Product A": 4565,
//             "Procuct B": 2556
//          },
//          {
//             name: "Apr 2019",
//             "Product A": 6654,
//             "Procuct B": 4465
//          },
//          {
//             name: "May 2019",
//             "Product A": 8765,
//             "Procuct B": 5553
//          }
//       ];
//       render() {
//          return (
//             <AreaChart
//                width={730}
//                height={250}
//                data={this.data}
//                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
//             <defs>
//                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//                   <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
//                   <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
//                </linearGradient>
//                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
//                   <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
//                   <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
//                </linearGradient>
//             </defs>
//             <XAxis dataKey="name" />
//             <YAxis />
//             <CartesianGrid strokeDasharray="3 3" />
//             <Tooltip />
//             <Legend />
//             <Area
//                type="monotone"
//                dataKey="Product A"
//                stroke="#8884d8"
//                fillOpacity={1}
//                fill="url(#colorUv)"/>
//             <Area
//                type="monotone"
//                dataKey="Procuct B"
//                stroke="#82ca9d"
//                fillOpacity={1}
//                fill="url(#colorPv)"/>
//             </AreaChart>
//       );
//    }
// }
// export default AreaRechartComponent;

// import React from "react";
// import { Line } from "react-chartjs-2";

// const data = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
//   datasets: [
//     {
//       label: "First dataset",
//       data: [33, 53, 85, 41, 44, 65],
//       fill: true,
//       backgroundColor: "rgba(75,192,192,0.2)",
//       borderColor: "rgba(75,192,192,1)"
//     }
//   ]
// };

// export default function BasicArea() {
//   return (
//     <Line data={data} />
//    );
// }

// import React from "react";
// import {
//     AreaChart,
//     Area,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
// } from "recharts";
 
// const BasicArea = () => {
//     const data = [
//         { name: "10.11.2022", x: 20, y: 70 },
//         { name: "11.11.2022", x: 40, y: 88 },
//         { name: "12.11.2022", x: 60, y: 85 },
//         { name: "13.11.2022", x: 35, y: 65 },
//         { name: "14.11.2022", x: 54, y: 46 },
//         { name: "15.11.2022", x: 72, y: 28 },
//         { name: "16.11.2022", x: 32, y: 68 },
//     ];
 
//     return (
//         <AreaChart width={500} height={700} data={data}>
//             <CartesianGrid />
//             <XAxis dataKey="name" />
//             <YAxis />
//             <Tooltip />
//             <Area
//                 dataKey="x"
//                 stackId="1"
//                 stroke="Orange"
//                 fill="Orange"
//             />
//             <Area
//                 dataKey="y"
//                 stackId="1"
//                 stroke="white"
//                 fill="white"
//             />
//         </AreaChart>
//     );
// };
 
// export default BasicArea;

// import React from 'react';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';
// //import 'chartjs-adapter-date-fns';

// import { parsedData } from './data';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const labels = parsedData.map((obj) => obj.timestamp);
// const values = parsedData.map((obj) => obj.value);

// export const options = {
//   responsive: true,
//   interaction: {
//     mode: 'index',
//     intersect: false,
//   },
//   plugins: {
//     legend: {
//       display: false,
//     },
//     tooltip: {},
//   },
//   scales: {
//     xAxis: {
//       type: 'time',
//       time: {
//         displayFormats: {
//           millisecond: 'MMM do hh:mm',
//           second: 'MMM do hh:mm',
//           minute: 'MMM do hh:mm',
//           hour: 'MMM do hh:mm',
//           day: 'MMM do hh:mm',
//           week: 'MMM do hh:mm',
//           month: 'MMM do hh:mm',
//           quarter: 'MMM do hh:mm',
//           year: 'MMM do hh:mm',
//         },
//       },
//     },
//     yAxis: {
//       min: 0,
//       max: 5,
//       ticks: {
//         stepSize: 1,
//       },
//     },
//   },
// };
// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Voltage',
//       data: values,
//       borderColor: 'rgb(255, 99, 132)',
//       pointRadius: 5,
//     },
//   ],
// };

// export default function BasicArea() {
//   return <Line options={options} data={data} />;
// }



// import React from 'react';
// import {
// AreaChart, Area,
// CartesianGrid, XAxis, YAxis, Tooltip
// } from 'recharts';
 


// const BasicArea = () => {
 
//     // Sample data
//     const data = [
//         { name: 'Geeksforgeeks', students: 400 },
//         { name: 'Technical scripter', students: 700 },
//         { name: 'Geek-i-knack', students: 200 },
//         { name: 'Geek-o-mania', students: 1000 }
//     ];

//     const CustomTooltip = ({ active, payload, label }) => {
//         if (active && payload && payload.length) {
//           return (
//             <div className="custom-tooltip">
//               <p className="label">{`${label} : ${payload[0].value}`}</p>
//               <p className="intro">{data}</p>
//               <p className="desc">Anything you want can be displayed here.</p>
//             </div>
//           );
//         }
      
//         return null;
//       };
 
//     return (
//         // <AreaChart width={600} height={600} data={data}>
//         //     <Area dataKey="students" fill="green" stroke="green" />
//         //     <CartesianGrid stroke="#ccc" />
//         //     <XAxis dataKey="name" />
//         //     <YAxis />
//         //     {/* <Tooltip content={<CustomTooltip />} /> */}
//         // </AreaChart>

//         <AreaChart width={600} height={600} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
//           <defs>
//             <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
//               <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
//               <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
//             </linearGradient>
//             <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
//               <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
//               <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
//             </linearGradient>
//           </defs>
//           <XAxis dataKey="name" />
//           <YAxis />
//           <CartesianGrid strokeDasharray="3 3" />
//           <Tooltip />
//           <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
//           <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
//       </AreaChart>
//     );
// }
 
// export default BasicArea;


import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
const data = [
  {
    name: "10.11.2022",
    uv: 57,
  },
  {
    name: "11.11.2022",
    uv: 25,
  },
  {
    name: "12.11.2022",
    uv: 35,
  },
  {
    name: "13.11.2022",
    uv: 40,
  },
  {
    name: "14.11.2022",
    uv: 25,
  },
  {
    name: "15.11.2022",
    uv: 30,
  },
  {
    name: "16.11.2022",
    uv: 20,
  },
];
function ReLineChart() {
  return (
    <div>
      <AreaChart
        width={500}
        height={260}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="uv" stroke="#b47818" fill="#ffebcc" />
      </AreaChart>
    </div>
  );
}
export default ReLineChart