import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsGraphUpArrow } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlineColorLens } from "react-icons/md";
import { LiaCopy } from "react-icons/lia";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegShareSquare } from "react-icons/fa";
import { PiFolderSimpleThin } from "react-icons/pi";
import { PiStarLight } from "react-icons/pi";
import { Link } from "react-router-dom";
import api from "../../../../Service/Axios";  // Assuming you have Axios instance setup
import { qr_code_delete } from "../../../../Service/ApiUrls"; // Your delete URL

function Action(props) {
  const { rowData, handleCopy, handleDelete } = props; // Receiving handleDelete from parent
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyClick = () => {
    const rowText = `Code Name: ${rowData.codeName}\nCode URL: ${rowData.codeUrl}\nCode Type: ${rowData.codeType}\nDescription: ${rowData.description}\nLocation: ${rowData.location}\nCTA Type: ${rowData.ctaType}\nStatus: ${rowData.status}`;
    navigator.clipboard.writeText(rowText).then(() => {
      alert("Row data copied to clipboard!");
    }).catch((err) => {
      console.error("Failed to copy: ", err);
    });
    handleClose();
  };

  // Call handleDelete when delete option is clicked
  const handleDeleteClick = () => {
    handleDelete(rowData.id);  // Pass the rowData.id to the delete function
    handleClose();
  };

  return (
    <div className="option">
      <div><p className="mb-0">Options</p></div>
      <div>
        <BsThreeDotsVertical className="cursor" onClick={handleClick} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem><BsGraphUpArrow /><Link to="/qrcode/individualqr"> &nbsp;QR Analysis</Link></MenuItem>
          <MenuItem><FiEdit3 />&nbsp;Edit Content</MenuItem>
          <MenuItem><MdOutlineColorLens /><Link to="/qrcode/qrdesign">&nbsp;Edit QR Design</Link></MenuItem>
          <MenuItem onClick={handleCopyClick}><LiaCopy />&nbsp;Make a copy</MenuItem>
          <MenuItem onClick={handleDeleteClick}><RiDeleteBinLine />&nbsp;Delete</MenuItem>
          <MenuItem><FaRegShareSquare />&nbsp;Share this QR</MenuItem>
          <MenuItem><PiFolderSimpleThin />&nbsp;Move to Folder</MenuItem>
          <MenuItem><PiStarLight />&nbsp;Add to Favorite</MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default React.memo(Action);
