import React from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { BiAddToQueue } from "react-icons/bi";
import search from "../../assets/image/search.svg";

function SearchBox(props) {
  const { key_name, value, onhandlechange } = props;

  return (
    <div className="W100">
      <div className="ui icon input search-bar d-flex justify-content-between">
        <input
          placeholder="Search"
          type="text"
          value={value}
          onChange={onhandlechange}
          name={key_name}
        />
        <i aria-hidden="true" className="search circular inverted link icon">
          <img src={search} alt="" />
        </i>
      </div>
    </div>
  );
}

export default React.memo(SearchBox);

