import React from "react";
import { Col, Row } from "react-bootstrap";
import Plans from "./Plans";

function AvailableBillingPlans() {
  return (
    <Row className="justify-content-center">
      <Col md={10}>
        <Row>
          {/* {Array.from({ length: 3 }, (_, index) => {
            return (
              <Col className="mb-3" md={4} lg={4}>
                <Plans />
              </Col>
            );
          })} */}
          {/* First Card: Basic Plan */}
          <Col className="mb-3" md={4} lg={4}>
            <Plans planType="Basic" price="20" availability="10 codes" validity="60" />
          </Col>
          {/* Second Card: Professional Plan */}
          <Col className="mb-3" md={4} lg={4}>
            <Plans planType="Professional" price="50" availability="20 codes" validity="90" />
          </Col>
          {/* Third Card: Customized Plan */}
          <Col className="mb-3" md={4} lg={4}>
            <Plans planType="Customized" price="200" availability="Unlimited" validity="90" />
          </Col>  
          <Col className="mb-3" md={12}>
            <p className="text-center f-16 fw-400 Inter-Regular">Subscribe to the Plan that best suits you</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default AvailableBillingPlans;
