import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
// import axios from "axios";
// import { LOGIN_URL } from "../../../service/Merchant/api/ApiUrls";

// export const SigninMerchant = createAsyncThunk(`signinMerchant`, async (body) => {
//   try {
//     const response = await axios.post(LOGIN_URL, body, {
//       headers: {
//         "access-control-allow-origin": "*",
//         "Content-Type": "application/json",
//       },
//       config: { withCredentials: true },
//     });
//     return await response?.data?.data;
//   } catch (err) {
//     return await err.response;
//   }
// });

export const SignoutMerchant = createAction("signoutmerchant");
