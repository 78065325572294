import React from "react";
import { Col, Row } from "react-bootstrap";
import RadioBtn from "../../../../Component/Merchant/RadioBtn";
import { Answers } from "../utils/utils";
import { Button } from "@mui/material";

function Feedback() {
  return (
    <Row>
      <Col md={10}>
        <div className="feedback">
          <Row>
            <Col className="bgcolor-lightgrey" md={6}>
              <div>
                <img
                  src={require("../../../../assets/image/settings/avatar.png")}
                  alt="i"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="p-4">
                <p className="f-24 fw-600 DMSans-Regular">
                  Share your Feedback and we value what you have to say.{" "}
                </p>
                <p className="f-18 fw-400 DMSans-Regular">
                  Question <span className="f-24 fw-600">1</span> of{" "}
                  <span className="f-24 fw-600">10</span>
                </p>
                <p className="f-18 fw-400 DMSans-Regular">
                  We wish to know how do you like our Platform?{" "}
                </p>
                {Answers.map((item, index) => {
                  return (
                    <div key={index}>
                      <RadioBtn sublabel={item.name} />
                    </div>
                  );
                })}
                <Button
                  variant="contained"
                  className="btn-download bgcolor-cerise mt-3"
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default Feedback;
