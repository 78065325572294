import React from "react";
import { Spinner } from "react-bootstrap"; // Import Spinner component
import "../assets/Css/style.css";


const Loader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      <Spinner animation="border" className="custom-round-spinner" /> {/* Custom class */}
      <span className="ms-2">Redirecting...</span>
    </div>
  );
};

export default Loader;
