import { useState } from "react";
import { commonFileUpload } from "../FileUpload/CommonFileUpload";

export const ConvertImage = async (file, path = "", condition) => {
  if (!path) {
    console.warn("No path provided for file upload.");
    return null;
  }
  let image = await commonFileUpload(file, path);
  console.log(image);
  if (condition) {
    const files = image.map((item) => ({ [condition]: item.fileName }));

    console.log("files", files)
    return files;
  }
  return image[0]?.fileName;
};

const useHandlechanges = ({ path = "" } = {}) => {
  const [formData, setFormData] = useState({});
  const [submit, setSubmit] = useState(false);

  const handleChange = async (event, condition) => {
    const { name, value, type, files } = event.target;
    console.log("name", name, value, type, files)
    console.log("condition", condition)

    if (type === "file" && path) {
      const fileName = await ConvertImage(files, path, condition);
      setFormData((prevState) => ({
        ...prevState,
        [name]: fileName,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return { formData, setFormData, submit, setSubmit, handleChange };
};

export default useHandlechanges;