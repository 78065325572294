import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SubHead from "../../../../Component/Merchant/SubHead";
import leftarrow from "../../../../assets/image/qrcode/arrow-left.svg";
import squarecheck from "../../../../assets/image/qrcode/square-check.svg";
import squareuncheck from "../../../../assets/image/qrcode/square-uncheck.svg";
import circlecheck from "../../../../assets/image/qrcode/circle-check.svg";
import circlecheckfull from "../../../../assets/image/qrcode/circle-checkfull.svg";
import qrcode4 from "../../../../assets/image/qrcode/qrcode4.svg";
import qrcode1 from "../../../../assets/image/qrcode/qrcode1.svg";
import qrcode2 from "../../../../assets/image/qrcode/qrcode2.svg";
import qrcode3 from "../../../../assets/image/qrcode/qrcode3.svg";
import line from "../../../../assets/image/qrcode/Line.svg";
import linefull from "../../../../assets/image/qrcode/Line-Full.svg";
import phone from "../../../../assets/image/qrcode/Screenshot 1.svg";
import { Height } from "@mui/icons-material";

function QrDesign() {
    const [checked, setChecked] = React.useState(false); 
    function handleChange(e) {
      setChecked(e.target.checked);
    }
    return (
        <>
            <SubHead head="QR code" first="Home" second="QR Code" third="All Codes" fourth="Create QR Code"/> 
            <br/>
            <Row>
                <Col>
                    <div className="left-arrow">
                    <h3 className="color-black DMSans-Regular f-18 fw-400"><img src={leftarrow} alt="leftarrow"/><Link to="/qrcode">Create New QR Code</Link></h3>
                    </div>
                </Col>
                <Col>
                    <div className="d-flex justify-content-end">
                        <button className="btn-download bgcolor-cerise">
                          <h1 className="DMSans-Regular f-18 fw-600 color-white">Watch Demo</h1>  
                        </button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="Qr-Type" md={12} lg={7}>
                    <Row >
                        <Col>
                            <h3 className="Inter-Regular f-16 fw-500 text-center color-emperor">Steps{' '}
                            <span className="Inter-Regular f-16 fw-700 text-center color-black">3</span>{' '}
                            <span className="Inter-Regular f-16 fw-500 text-center color-emperor">of 3</span></h3>                     
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3 check-layout">
                        <Col className="mb-3">
                            <img src={circlecheckfull} alt=""></img> 
                            <h3 className="Inter-Regular f-16 fw-500 color-emperor">QR Details</h3>
                        </Col>
                        <Col className="mb-3">
                            <img src={line} alt=""></img> 
                        </Col>
                        <Col className="mb-3">
                            <img src={circlecheckfull} alt=""></img> 
                            <h3 className="Inter-Regular f-16 fw-500 color-emperor">QR Contents</h3>
                        </Col>
                        <Col className="mb-3">
                            <img src={line} alt=""></img> 
                        </Col>
                        <Col className="mb-3">
                            <img src={circlecheck} alt=""></img> 
                            <h3 className="Inter-Regular f-16 fw-500 color-emperor">QR Design</h3>
                        </Col>
                    </Row>
                    <Row>
                        <span><input value = "test" type = "checkbox" onChange = {handleChange} /><img src={qrcode4} alt=""></img></span>
                    </Row>
                    <Row>
                        <Col>
                            <span><input value = "test" type = "checkbox" onChange = {handleChange} /><img src={qrcode1} alt=""></img></span>
                        </Col>
                        <Col>
                            <span><input value = "test" type = "checkbox" onChange = {handleChange} /><img src={qrcode2} alt=""></img></span>
                        </Col>
                        <Col>
                            <span><input value = "test" type = "checkbox" onChange = {handleChange} /><img src={qrcode3} alt=""></img></span>
                        </Col>
                        <Col>
                            <span><input value = "test" type = "checkbox" onChange = {handleChange} /><img src={qrcode3} alt=""></img></span>
                        </Col>
                    </Row>
                    <Row className="d-inline-flex">
                    <Col className="d-flex justify-content-start">
                        <button className="btn-download bgcolor-white">
                          <h1 className="DMSans-Regular f-18 fw-600 color-blak">Back</h1>  
                        </button>
                    </Col>
                    <Col className="d-flex justify-content-between">
                        <button className="btn-download bgcolor-cerise">
                          <h1 className="DMSans-Regular f-18 fw-600 color-white">Create</h1>  
                        </button>
                    </Col>
                    </Row>
                </Col>
                <Col>
                    <div>
                        <img src={qrcode4} alt="" style={{ alignSelf: 'center' }}></img>
                    </div>
                    <br/>
                    <div className="d-flex justify-content-between">
                        <button className="btn-download bgcolor-white">
                          <h1 className="DMSans-Regular f-18 fw-600 color-black">Preview Page View</h1>  
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    );
}
export default QrDesign;