import React, { useState, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import useHandlechanges from "../../../../../Hooks/useHandlechanges";


const WarrantyModuleUpload = ({ handleInputChange }) => {
  const [warrantyData, setWarrantyFormData] = useState({
    cta_name: "",
    warranty_duration_type: "",
    warranty_duration: "",
    warranty_description: "",
    end_screen_message: "",
    product_images: [],
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const { formData, setFormData, handleChange } = useHandlechanges({
    
    path: "templates"
    
   });
   console.log("doc formData", formData)

  const processFiles = (files) => {
    const validFiles = Array.from(files).filter(
      (file) =>
        file.size <= 25 * 1024 * 1024 && // Check file size (<= 25 MB)
        /\.(jpg|jpeg|png)$/i.test(file.name) // Check file format
    );

    if (validFiles.length !== files.length) {
      alert("Some files were invalid or exceeded 25MB.");
    }

    const uploaded = validFiles.map((file) => ({
      image: URL.createObjectURL(file),
    }));

    setUploadedFiles((prev) => [...prev, ...uploaded]);

    setWarrantyFormData((prev) => ({
      ...prev,
      product_images: [...prev.product_images, ...uploaded],
    }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    processFiles(files);
  };

  useEffect(() => {
    const warrantyModule = {
      target: {
        name: "warrenty_modules",
        value: [formData],
      },
    };
    handleInputChange(warrantyModule);
  }, [formData]);

  return (
    <div
      className="border-container"
      style={{
        border: "1px solid #ccc",
        padding: "20px",
        marginTop: "20px",
      }}
    >
      <Form.Label className="form-label-h">Warranty Module Details</Form.Label>

      {/* CTA Name */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter CTA Name"
            name="cta_name"
            value={formData.cta_name}
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Warranty Duration Type */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Select
            name="warranty_duration_type"
            value={formData.warranty_duration_type}
            onChange={handleChange}
          >
            <option value="">Select Duration Type</option>
            <option value="weeks">Weeks</option>
            <option value="days">Days</option>
            <option value="months">Months</option>
          </Form.Select>
        </Col>
      </Row>

      {/* Warranty Duration */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="number"
            placeholder="Enter Warranty Duration"
            name="warranty_duration"
            value={formData.warranty_duration}
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Warranty Description */}
      <Row className="mb-3">
        <Col md={12}>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter Warranty Description"
            name="warranty_description"
            value={formData.warranty_description}
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* End Screen Message */}
      <Row className="mb-3">
        <Col md={12}>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter End Screen Message"
            name="end_screen_message"
            value={formData.end_screen_message}
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Upload Product Images */}
      <Row className="mb-3">
        <Col md={12}>
          <div
            style={{
              border: isDragOver ? "2px dashed #007bff" : "2px dashed #ccc",
              padding: "20px",
              textAlign: "center",
              backgroundColor: isDragOver ? "#e9f7ff" : "#fff",
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <p>Drag and drop images here or click to browse</p>
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              multiple
              style={{ display: "none" }}
              id="upload-input"
              name="product_images"
              onChange={(e) => handleChange(e, "image")}
            />
            <label
              htmlFor="upload-input"
              style={{
                cursor: "pointer",
                backgroundColor: "#f7f7f7",
                padding: "10px 20px",
                borderRadius: "5px",
                display: "inline-block",
              }}
            >
              Browse Files
            </label>
            <p className="mt-3">
              Supported formats: .jpg, .jpeg, .png | Max size: 25MB
            </p>
          </div>
        </Col>
      </Row>

      {/* Uploaded Files */}
      <Row className="mb-3">
        <Col md={12}>
          {uploadedFiles.length > 0 && (
            <Card>
              <Card.Header>Uploaded Images</Card.Header>
              <Card.Body>
                <ul>
                  {uploadedFiles.map((file, index) => (
                    <li key={index}>
                      <img
                        src={file.image}
                        alt={`Uploaded ${index}`}
                        style={{ maxWidth: "100px", marginRight: "10px" }}
                      />
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default WarrantyModuleUpload;
