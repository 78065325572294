import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import api from "../../../../Service/Axios";
import { profile_details } from "../../../../Service/ApiUrls";
import { InformationForm } from "../utils/utils";
import { useSelector } from "react-redux";


function Information() {
  const UserInfo = useSelector((state) => state?.profile?.profile);
  console.log("info", UserInfo)
  // State to hold user information
 

 

  return (
    <div className="cards">
      <div className="d-flex justify-content-between align-items-center border-bottom py-3">
        <div className="d-flex">
          <div className="me-2">
            <img
              src={require("../../../../assets/image/avatar.png")}
              alt="i"
              width={60}
            />
          </div>
          <div className="user">
            <small className="name Gilroy-semibold f-16 fw-600">
              {UserInfo.client_name.toUpperCase() || "Loading..."} 
            </small>
            <br />
            <small className="Gilroy-regular user-mail f-14 fw-500">
              {UserInfo.role_name || "Loading..."} 
            </small>
          </div>
        </div>
        <Button variant="contained" className="btn-download bgcolor-cerise">
          Save
        </Button>
      </div>
      <div className="border-bottom py-3">
        {InformationForm.map((item, i) => {
          return <item.tag key={i} {...item} />;
        })}
      </div>
      <div>
        <small className="d-block f-16 fw-600 DMSans-Regular color-black mt-3">
          Delete account
        </small>
        <ul>
          <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
            Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
            eiusmod tempor incididunt.
          </li>
        </ul>
        <Button variant="contained" className="btn-download bgcolor-cerise">
          Delete my account
        </Button>
      </div>
    </div>
  );
}

export default Information;
