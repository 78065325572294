import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Actions from "./Actions"; // Import the Action component
import api from "../../../../Service/Axios"; // Assuming you have Axios instance setup
import { qr_code_list, qr_code_delete } from "../../../../Service/ApiUrls"; // Your API URLs

export default function AllList({ searchTerm, activeStatus, codeType, ctaType }) {
  const [qrCodeData, setQrCodeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [locationCache, setLocationCache] = useState({}); // Cache for location names


//   const fetchLocationName = async (latLong) => {
//     if (!latLong) return "Unknown Location";

//     if (locationCache[latLong]) return locationCache[latLong];

//     const [latitude, longitude] = latLong.split(",").map((coord) => coord.trim());
//     const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

//     try {
//         const response = await fetch(url);
//         const data = await response.json();

//         if (data && data.address) {
//             const locationName = `${data.address.city}, ${data.address.country}`;
//             setLocationCache((prev) => ({ ...prev, [latLong]: locationName })); 
//             return locationName;
//         } else {
//             console.error("Nominatim API Error:", data);
//             return "Unknown Location";
//         }
//     } catch (error) {
//         console.error("Error fetching location name:", error);
//         return "Unknown Location";
//     }
// };

  


  // Fetch QR Code list
  useEffect(() => {
    const fetchQrCodeData = async () => {
      try {
        const response = await api.get(qr_code_list);
        const result = response.data;

        if (result.status && result.data?.data) {
          const transformedData = await Promise.all(
            result.data.data.map(async (item) => {
              // const locationName = await fetchLocationName(item.lat_long || "");
              // console.log("loaction name", locationName)
              return {
                id: item.id,
                codeName: item.qrcode_name,
                codeUrl: item.qrcode_value,
                codeType: item.qrcode_type.toString(),
                description: item.description,
                location: item.location,
                ctaType: item.cta_category_name,
                ctaTypeId: item.cta_category_id,
                status: item.create_status === "completed" ? "Active" : "Inactive",
              };
            })
          );
          setQrCodeData(transformedData);
        } else {
          console.error("Failed to fetch QR Code data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching QR Code data:", error);
      }
    };

    fetchQrCodeData();
  }, []);


  // Filter data
  useEffect(() => {
    const filterData = () => {
      let filtered = qrCodeData;

      // Search Term Filter
      if (searchTerm) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        filtered = filtered.filter(
          (item) =>
            item.codeName.toLowerCase().includes(lowerSearchTerm) ||
            item.codeType.toLowerCase().includes(lowerSearchTerm) ||
            item.ctaType.toLowerCase().includes(lowerSearchTerm)
        );
      }

      // Active Status Filter
      if (activeStatus !== 0) {
        const statusFilter = activeStatus === 1 ? "Active" : "Inactive";
        filtered = filtered.filter((item) => item.status === statusFilter);
      }

      // Code Type Filter
      if (codeType !== 0) {
        const codeTypeFilter = codeType === 1 ? "Static" : "Dynamic";
        filtered = filtered.filter((item) => item.codeType.toLowerCase() === codeTypeFilter.toLowerCase());
      }

      // CTA Type Filter
      if (ctaType !== 0) {
        filtered = filtered.filter((item) => item.ctaTypeId === ctaType); // Ensure `ctaTypeId` is used for comparison
      }

      setFilteredData(filtered);
    };

    filterData();
  }, [qrCodeData, searchTerm, activeStatus, codeType, ctaType]);

  // Handle Delete
  const handleDelete = async (id) => {
    try {
      const response = await api.delete(`${qr_code_delete}/${id}`);
      return response.status === 200; // QR code exists
    } catch (error) {
      return false; // QR code does not exist
    }
  };

  return (
    <div className="custom-table mt-3">
      <Table>
        <thead>
          <tr className="f-14 fw-500 tableHead">
            <th>ID</th>
            <th>Code Name</th>
            <th>Code Url</th>
            <th>Code Type</th>
            <th>Description</th>
            <th>Location</th>
            <th>CTA Type</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((value, key) => (
            <tr key={key}>
              <td className="f-16 fw-500 tableText">{value.id}</td>
              <td className="f-16 fw-500 tableText">{value.codeName}</td>
              <td className="f-16 fw-400 tableText">{value.codeUrl}</td>
              <td className="f-16 fw-400 tableText">{value.codeType}</td>
              <td className="f-16 fw-400 tableText">{value.description}</td>
              <td className="f-16 fw-400 tableText">{value.location}</td>
              <td className="f-16 fw-400 tableText">{value.ctaType}</td>
              <td className="f-16 fw-400 tableText">
                <small className={value.status === "Active" ? "status-active" : "status-inactive"}>
                  {value.status}
                </small>
              </td>
              <td>
                <div className="mt-3">
                  <Actions rowData={value} handleDelete={handleDelete} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
