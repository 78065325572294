import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

const Contact = ({ handleInputChange }) => {
  const [contactData, setContactData] = useState({
    company_url: "",
    full_name: "",
    company_name: "",
    email_id: "",
    mobile_number: "",
    address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const { company_url, full_name, company_name, email_id, mobile_number, address } = contactData;
    if (company_url && full_name && company_name && email_id && mobile_number && address) {
      // Trigger parent function to handle the data
      const event = {
        target: {
          name: "contact_info",
          value: [contactData], // Pass data in the required format
        },
      };
      handleInputChange(event);
    }
  }, [contactData]);

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}
    >
      <Row className="mb-3">
        <Form.Label htmlFor="contactDetails" className="form-label-h">
          Contact Details
        </Form.Label>

        {/* Company URL */}
        <Col md={6} className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter Company URL"
            value={contactData.company_url}
            name="company_url"
            onChange={handleChange}
          />
        </Col>

        {/* Full Name */}
        <Col md={6} className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter Full Name"
            value={contactData.full_name}
            name="full_name"
            onChange={handleChange}
          />
        </Col>

        {/* Company Name */}
        <Col md={6} className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter Company/Organization"
            value={contactData.company_name}
            name="company_name"
            onChange={handleChange}
          />
        </Col>

        {/* Email ID */}
        <Col md={6} className="mb-3">
          <Form.Control
            type="email"
            placeholder="Enter Email ID"
            value={contactData.email_id}
            name="email_id"
            onChange={handleChange}
          />
        </Col>

        {/* Mobile Number */}
        <Col md={6} className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter Mobile Number"
            value={contactData.mobile_number}
            name="mobile_number"
            onChange={handleChange}
          />
        </Col>

        {/* Address */}
        <Col md={12} className="mb-3">
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Enter Address"
            value={contactData.address}
            name="address"
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Display contact data in a card (optional, can be removed if not needed) */}
      {Object.values(contactData).every((field) => field) && (
        <Card className="mt-4">
          <Card.Header>Added Contact Details</Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <strong>Company URL:</strong> {contactData.company_url}
              </Col>
              <Col md={6}>
                <strong>Full Name:</strong> {contactData.full_name}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <strong>Company/Organization:</strong> {contactData.company_name}
              </Col>
              <Col md={6}>
                <strong>Email ID:</strong> {contactData.email_id}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <strong>Mobile Number:</strong> {contactData.mobile_number}
              </Col>
              <Col md={6}>
                <strong>Address:</strong> {contactData.address}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Contact;
