// import React from "react";
// import DonutChart from 'react-donut-chart';
// // import Static from "../../../Component/Merchant/Charts/DonutChart";
// // import Dynamic from "../../../Component/Merchant/Charts/DonutChart";
// // import Tabs from "../../../Component/Merchant/Tabs";
// // import { DashTabs } from "../../../pages/Merchant/Dashboard/Utils/utils";
// // import { useState } from "react";

// //import {Doughnut} from "react-chartjs-2";
// // const chartData = [
// //   { label: "Complete Success", value: 70 },
// //   { label: "Partial Success", value: 20 },
// //   { label: "Non Working", value: 10 }
// // ];

// const chartData = [
//   { label: "", value: 70 },
//   { label: "", value: 20 },
// ];

// const COLORS = ["#5EB02B", "#d235c3"];

// export default function DoChart() {
//   //const [activeId, setActiveId] = useState(1);
//   return (
//     <div className="mt-4">
//         {/* <Row >
//         <Col md={12} lg={8}>
//           <Tabs
//             values={DashTabs}
//             activeId={activeId}
//             setActiveId={setActiveId}
//           />{" "}
//         </Col>
//       </Row> */}
//       <DonutChart data={chartData} colors={COLORS} width={270} height={300}/>
//       {/* {activeId === 1 && <Static />}
//       {activeId === 2 && <Dynamic />} */}
//     </div>
//   );
// }



// import React from "react";
// import { Doughnut, Chart } from "react-chartjs-2";

// var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
// Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
//   draw: function() {
    // originalDoughnutDraw.apply(this, arguments);

    // var chart = this.chart.chart;
//     var ctx = chart.ctx;
//     var width = chart.width;
//     var height = chart.height;

//     var text = chart.config.data.text;
//     // console.log(chart, ctx)

//     var sidePadding = 20;
//     var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);

//     ctx.font = "30px " + "Arial";


//     var stringWidth = ctx.measureText(text).width;
//     var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;


//     var widthRatio = elementWidth / stringWidth;
//     var newFontSize = Math.floor(30 * widthRatio);
//     var elementHeight = chart.innerRadius * 2;


//     var fontSizeToUse = Math.min(newFontSize, elementHeight);

//     var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
//     var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

//     var fontSize = (height / 114).toFixed(2);
//     ctx.font = fontSize + "em Verdana";
//     ctx.textBaseline = "middle";

//     ctx.textAlign = "center";
//     ctx.font = fontSizeToUse + "px " + "Arial";

//     var textX = Math.round((width - ctx.measureText(text).width) / 2);
//     var textY = height / 2;

//     ctx.fillText(text, centerX, centerY);
//   }
// });

// const data = {
//   labels: ["Red", "Green", "Yellow"],
//   datasets: [
//     {
//       data: [300, 50, 100],
//       backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
//       hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
//     }
//   ],
//   text: "Total: 9000+"
// };

// const options = {
//   legend: {
//     display: true,
//     position: "right"
//   }
// };

// export default function DoChart() {
//   //render() {
//     return (
//       <div>
//         <h2>React Doughnut with Text Example</h2>
//         <Doughnut data={data} options={options} />
//       </div>
//     );
//   //}
// }

// import React from "react";
// import { PieChart, Pie, Tooltip } from "recharts";
 
// const DoChart = () => {
//     // Sample data
//     const data = [
//         { name: "Geeksforgeeks", students: 400 },
//         { name: "Technical scripter", students: 700 },
//         { name: "Geek-i-knack", students: 200 },
//         { name: "Geek-o-mania", students: 1000 },
//     ];
 
//     return (
//         <div
//             style={{
//                 textAlign: "center",
//                 margin: "auto 10%",
//             }}
//         >
            
//             <PieChart width={1000} height={1000}>
//                 <Tooltip />
//                 <Pie
//                     data={data}
//                     dataKey="students"
//                     outerRadius={500}
//                     innerRadius={300}
                    
//                     label={({ name, students }) =>
//                         `${name}: ${students}`
//                     }
//                 />
//             </PieChart>
//         </div>
//     );
// };
 
// export default DoChart;


import React, { PureComponent } from "react";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-doughnut-innertext";
//import "chartjs-plugin-outerlabels";
import Static from "../../../Component/Merchant/Charts/HalfDonutChart";
import Dynamic from "../../../Component/Merchant/Charts/HalfDonutChart";
import Tabs from "../../../Component/Merchant/Tabs";
import { DashTabs } from "../../../pages/Merchant/Dashboard/Utils/utils";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

const data = {
  datasets: [
    {
      
      fill: false,
      lineTension: 0.0,
      backgroundColor: ["#d235c3", "#5EB02B"],
      hoverBackgroundColor: ["#d235c3", "#5EB02B"],
      data: [30 , 50]
    }
  ]
};

const listItemStyle = {
  color: "#333",
  listStyle: "none",
  textAlign: "left",
  display: "flex",
  flexDirection: "row",
  margin: "8px"
};
const options = {
  plugins: {
    legend: true,
    textInside: true // Enable the custom plugin
    
  }
};

const plugins = [{
  beforeDraw: function(chart) {
   var width = chart.width,
       height = chart.height,
       ctx = chart.ctx;
       ctx.restore();
       var fontSize = (height / 200).toFixed(2);
       ctx.font = fontSize + "em sans-serif";
       ctx.textBaseline = "top";
       var text = "Total Code 80",
       textX = Math.round((width - ctx.measureText(text).width) / 2),
       textY = height / 2;
       ctx.fillText(text, textX, textY);
       ctx.save();
  } 
}]

export const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    ticks: {
      display: true,
    },
  },
};

const DoughnutDemo = () => {
    const [activeId, setActiveId] = useState(1);
    return (
      <div>
        <Row >
        <Col md={12} lg={8}>
          <Tabs
            values={DashTabs}
            activeId={activeId}
            setActiveId={setActiveId}
          />{" "}
        </Col>
      </Row>
        <Doughnut
          // ref={(ref) => (this.Doughnut = ref)}
          data={data}
          plugins={plugins}
          // chartOptions={chartOptions}
          options= {{
            responsive: true,
            cutout:"80%",
            plugins: {
               legend: {
                  display: true,
                  position: 'bottom',
                  align: 'center',
                  labels: {
                     color: 'darkred',
                     font: {
                        weight: 'bold'
                     },
                  }
               },
               doughnutlabel: {
                labels: [
                  {
                    text: '550',
                    font: {
                      size: 20,
                      weight: 'bold',
                    },
                  },
                  {
                    text: 'total',
                  },
                ],
              },
            }
          }
         }          
        />
      </div>
    );
};
export default DoughnutDemo;
