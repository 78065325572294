import React from "react";

function Tabs({ values, activeId, setActiveId }) {
  return (
    <div className="tab">
      <ul>
        {values.map((val, i) => (
          <li
            onClick={() => setActiveId(val.id)}
            key={i}
            className={
              activeId === val.id ? "current DMSans-Regular" : "DMSans-Regular"
            }
          >
            {activeId === val.id && (
              <>
                <div className="border-shape"></div>
                <div className="d-flex justify-content-center">
                  <div className="custom-shape"></div>
                </div>
              </>
            )}
            <p className="mb-0 mx-2">{val.name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default React.memo(Tabs);
