import React, { FunctionComponent, useState } from "react";
import Table from "react-bootstrap/Table";
import { LiaCopy } from "react-icons/lia";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const allcode = [
//     {
//       codename: "Group Code 1",
//       codeurl: "linkoo.web/34meiprs.com ",
//       name: "Sample Code, Test 1, Test 2, Test 7",
//       description: "Lorem ipsum dolor",
//       noofcode: "4",
//       status: "Active",
//       actions:"View"
//     },
//     {
//       codename: "Group Code 2",
//       codeurl: "linkoo.web/34meiprs.com ",
//       name: "Test 2, Test 8",
//       description: "sit amet, consectetur",
//       noofcode: "2",
//       status: "Inactive",
//       actions:"View"
//     },
//     {
//       codename: "Group Code 3",
//       codeurl: "linkoo.web/34meiprs.com ",
//       name: "Test 9, Test 6, Test 5",
//       description: "Lorem ipsum dolor",
//       noofcode: "3",
//       status: "Active",
//       actions:"View"
//     },
//   ];

export default function AllList() {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    // <div className="custom-table mt-3">
    //   <Table>
    //     <thead>
    //       <tr className="f-14 fw-500 tableHead">
    //         <th>Group Code Name</th>
    //         <th>Group Code Url</th>
    //         <th>Name of QR Codes</th>
    //         <th>Description</th>
    //         <th>No.of QR Codes</th>
    //         <th>Status</th>
    //         <th>Action</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {allcode.map((value, key) => {
    //         return (
    //           <tr key={key}>
    //             <td className="f-16 fw-500 tableText">{value.codename}</td>
    //             <td className="f-16 fw-400 tableText">{value.codeurl}<span><img src={copy} alt="copy"/></span></td>
    //             <td className="f-16 fw-400 tableText">{value.name}</td>
    //             <td className="f-16 fw-400 tableText">{value.description}</td>
    //             <td className="f-16 fw-400 tableText">{value.noofcode}</td>
    //             <td className="f-16 fw-400 tableText">{value.status}</td>
    //             <td className="f-16 fw-400 tableText">{value.actions}</td>
    //           </tr>
    //         );
    //       })}
    //     </tbody>
    //   </Table>
    // </div>
    <div className="custom-table mt-3">
      {/* <ToastContainer /> */}
      <Table>
        <thead>
          <tr className="f-14 fw-500 tableHead">
            <th>Group Code Name</th>
            <th>Group Code Url</th>
            <th>Name of QR Codes</th>
            <th>Description</th>
            <th>No.of QR Codes</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="f-16 fw-500 tableText">Group Code 1</td>
            <td className="f-16 fw-400 tableText">
              linkoo.web/34meiprs.com{" "}
              <CopyToClipboard
                text="linkoo.web/34meiprs.com"
                onCopy={handleCopy}
              >
                <span style={{ cursor: "pointer" }}>
                  <LiaCopy />
                  {copied && (
                    <span
                      className="tooltip"
                      style={{
                        position: "relative", // Use relative positioning for the parent element
                        top: "100%", // Display the tooltip below the parent element
                        left: "-5px", // Adjust the left position as needed
                        backgroundColor: "Grey",
                        color: "black",
                        fontWeight: 500,
                        padding: "5px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        opacity: 0.8,
                        width: "70px"
                      }}
                    >
                      Copied!
                    </span>
                  )}
                </span>
              </CopyToClipboard>
            </td>
            <td className="f-16 fw-400 tableText">
              Sample Code, Test 1, Test 2, Test 7
            </td>
            <td className="f-16 fw-400 tableText">Lorem ipsum dolor</td>
            <td className="f-16 fw-400 tableText">4</td>
            <td className="f-16 fw-400 tableText">
              <small className="status-active">Active</small>
            </td>
            <td>
              <div className="mt-3">
                <Link to="/qrcode/groupcodeview">View</Link>
              </div>
            </td>
          </tr>
          <tr>
            <td className="f-16 fw-500 tableText">Group Code 2</td>
            <td className="f-16 fw-400 tableText">
              linkoo.web/34meiprs.com
              <LiaCopy />
            </td>
            <td className="f-16 fw-400 tableText">Test 2, Test 8</td>
            <td className="f-16 fw-400 tableText">sit amet, consectetur</td>
            <td className="f-16 fw-400 tableText">2</td>
            <td className="f-16 fw-400 tableText">
              <small className="status-inactive">Inactive</small>
            </td>
            <td>
              <div className="mt-3">
                <Link to="/qrcode/groupcodeview">View</Link>
              </div>
            </td>
          </tr>
          <tr>
            <td className="f-16 fw-500 tableText">Group Code 3</td>
            <td className="f-16 fw-400 tableText">
              linkoo.web/34meiprs.com
              <LiaCopy />
            </td>
            <td className="f-16 fw-400 tableText">Test 9, Test 6, Test 5</td>
            <td className="f-16 fw-400 tableText">Lorem ipsum dolor</td>
            <td className="f-16 fw-400 tableText">3</td>
            <td className="f-16 fw-400 tableText">
              <small className="status-active">Active</small>
            </td>
            <td>
              <Link to="/qrcode/groupcodeview">View</Link>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
