import React, { useState, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import useHandlechanges from "../../../../../Hooks/useHandlechanges";

const DocumentUpload = ({ handleInputChange }) => {
  const [documentData, setDocumentData] = useState({
    cta_name: "",
    end_screen_message: "",
    folder_name: "",
    gallery_images: [],
  });

  const { formData, setFormData, handleChange } = useHandlechanges({
    
    path: "templates"
    
   });
   console.log("doc formData", formData)

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const processFiles = (files) => {
    const validFiles = Array.from(files).filter(
      (file) =>
        file.size <= 25 * 1024 * 1024 && // Check file size (<= 25 MB)
        /\.(pdf|doc|docx|xls|xlsx|jpg|jpeg|png)$/i.test(file.name) // Check file format
    );

    if (validFiles.length !== files.length) {
      alert("Some files were invalid or exceeded 25MB.");
    }

    const uploaded = validFiles.map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
    }));

    setUploadedFiles((prev) => [...prev, ...uploaded]);

    // Add only image files to gallery_images
    const imageFiles = validFiles.filter((file) =>
      /\.(jpg|jpeg|png)$/i.test(file.name)
    );

    const galleryImages = imageFiles.map((file) => ({
      image: URL.createObjectURL(file),
    }));

    setDocumentData((prev) => ({
      ...prev,
      gallery_images: [...prev.gallery_images, ...galleryImages],
    }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    processFiles(files);
  };

  useEffect(() => {
    if (formData.cta_name && formData.end_screen_message) {
      const documentEvent = {
        target: {
          name: "documents",
          value: [formData],
        },
      };
      handleInputChange(documentEvent);
    }
  }, [formData]);

  return (
    <div
      className="border-container"
      style={{
        border: "1px solid #ccc",
        padding: "20px",
        marginTop: "20px",
      }}
    >
      <Form.Label className="form-label-h">Document Details</Form.Label>

      {/* CTA Name */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter CTA Name"
            // value={documentData.cta_name}
            name="cta_name"
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Upload Documents */}
      <Row className="mb-3">
        <Col md={12}>
          <div
            style={{
              border: isDragOver ? "2px dashed #007bff" : "2px dashed #ccc",
              padding: "20px",
              textAlign: "center",
              backgroundColor: isDragOver ? "#e9f7ff" : "#fff",
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <p>Drag and drop files here or click to browse</p>
            <input
              type="file"
              accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
              multiple
              style={{ display: "none" }}
              id="upload-input"
              name="gallery_images"
              onChange={(e) => handleChange(e, "image")}
            />
            <label
              htmlFor="upload-input"
              style={{
                cursor: "pointer",
                backgroundColor: "#f7f7f7",
                padding: "10px 20px",
                borderRadius: "5px",
                display: "inline-block",
              }}
            >
              Browse Files
            </label>
            <p className="mt-3">
              Supported formats: .pdf, .word, .excel, .jpg, .jpeg, .png | Max
              size: 25MB
            </p>
          </div>
        </Col>
      </Row>

      {/* Uploaded Files */}
      <Row className="mb-3">
        <Col md={12}>
          {uploadedFiles.length > 0 && (
            <Card>
              <Card.Header>Uploaded Files</Card.Header>
              <Card.Body>
                <ul>
                  {uploadedFiles.map((file, index) => (
                    <li key={index}>
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name} ({file.url})
                      </a>
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      {/* End Screen Message */}
      <Row className="mb-3">
        <Col md={12}>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter End Screen Message"
            // value={documentData.end_screen_message}
            name="end_screen_message"
            onChange={handleChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DocumentUpload;
