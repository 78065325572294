import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Table from "./ArchivedQRTable"; // Adjust the import path as necessary
import SubHead from "../../../../Component/Merchant/SubHead";
import SearchBox from "../../../../Component/Merchant/SearchBox";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import api from "../../../../Service/Axios";
import { cta_categories } from "../../../../Service/ApiUrls";

function ArchivedQRCode() {
  const [searchTerm, setSearchTerm] = useState("");
  const [codeType, setCodeType] = useState(0);
  const [ctaType, setCTAType] = useState(0);
  const [codeTypeOptions, setCodeTypeOptions] = useState([
    { label: "Code Type", value: 0 },
    { label: "Static", value: 1 },
    { label: "Dynamic", value: 2 },
  ]);
  const [ctaTypeOptions, setCtaTypeOptions] = useState([]);

  // Fetch CTA Categories and update dropdown options
  useEffect(() => {
    const fetchCTACategories = async () => {
      try {
        const response = await api.get(cta_categories);
        if (response.data?.data?.cta_categories) {
          const ctaOptions = response.data.data.cta_categories.map((item) => ({
            label: item.cta_category_name,
            value: item.id,
          }));
          setCtaTypeOptions([{ label: "CTA Type", value: 0 }, ...ctaOptions]);
        } else {
          console.error("Unexpected API response:", response.data);
        }
      } catch (error) {
        console.error("Error fetching CTA categories:", error);
        setCtaTypeOptions([{ label: "No CTA Types Available", value: 0 }]);
      }
    };

    fetchCTACategories();
  }, []);

  return (
    <>
      <React.Fragment>
        <br />
        <div>
          <Row>
            <Col md={6} lg={4}>
              <SearchBox onhandlechange={(e) => setSearchTerm(e.target.value)} />
            </Col>
            <Col md={12} lg={8}>
              <Dropdown
                list={codeTypeOptions}
                value={codeType}
                onhandlechange={(e) => setCodeType(Number(e.target.value))}
              />
              <Dropdown
                list={ctaTypeOptions}
                value={ctaType}
                onhandlechange={(e) => setCTAType(Number(e.target.value))}
              />
            </Col>
          </Row>
        </div>
        <Table
          searchTerm={searchTerm}
          codeType={codeType}
          ctaType={ctaType}
        />
      </React.Fragment>
    </>
  );
}

export default ArchivedQRCode;
