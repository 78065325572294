import { Col, Row } from "react-bootstrap";
import SubHead from "../../../Component/Merchant/SubHead";
import Tabs from "../../../Component/Merchant/Tabs";
import { useState } from "react";
import BillingTable from "./BillingTable";
import BillingTiles from "./BillingTiles";
import AvailablePalns from "./AvailablePlans"

export const BillingTabs = [
  { id: 1, name: "Billing Information" },
  { id: 2, name: "Available Billing Plans" },
];

function Billing() {
  const [activeId, setActiveId] = useState(1);

  const getSubHeadThird = () => {
    switch (activeId) {
      case 1:
        return "Billing Information";
      case 2:
        return "Available Billing Plans";
      default:
        return "";
    }
  };

  return (
    <>
      <SubHead head="Billing" first="Home" second="Billing" third={getSubHeadThird()} />
      <Row className="mt-2">
        <Col md={12}>
          <Tabs values={BillingTabs} activeId={activeId} setActiveId={setActiveId} />
        </Col>
      </Row>
      <Row className="mt-4">
      {activeId === 1 && (
          <>
            <BillingTiles /> {/* Show BillingTiles when activeId is 1 */}
            <BillingTable /> {/* Show BillingTable when activeId is 1 */}
          </>
        )}
        {activeId === 2 && <AvailablePalns/>} {/* Tab for "Details" */}
      </Row>
    </>
  );
}

export default Billing;



// import { Col, Row } from "react-bootstrap";
// import BillingTiles from "./BillingTiles";
// import BillingTable from "./BillingTable";
// import SubHead from "../../../Component/Merchant/SubHead";

// function Billing() {
//   return (
//     <>
//       <SubHead head="Billing" first="Home" second="Billing" />
//       <BillingTiles />
//       <BillingTable />
//     </>
//   );
// }
// export default Billing;
