import React, { FunctionComponent, useState } from "react";
import Table from 'react-bootstrap/Table';
import { LiaCopy } from "react-icons/lia";
import Actions from "./Actions";
import { CopyToClipboard } from "react-copy-to-clipboard";

  export default function AllList() {
    const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

    return (   
        <div className="custom-table mt-3">
        <Table>
          <thead>
            <tr className="f-14 fw-500 tableHead">
             <th>Code Name</th>
             <th>Code Url</th>
             <th>Code Type</th>
             <th>Description</th>
             <th>Location</th>
             <th>CTA Type</th>
             <th>Status</th>
             <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="f-16 fw-500 tableText">Sample Test</td>
              <td className="f-16 fw-400 tableText">
              linkoo.web/34meiprs.com{" "}
              <CopyToClipboard
                text="linkoo.web/34meiprs.com"
                onCopy={handleCopy}
              >
                <span style={{ cursor: "pointer" }}>
                  <LiaCopy />
                  {copied && (
                    <span
                      className="tooltip"
                      style={{
                        position: "relative", // Use relative positioning for the parent element
                        top: "100%", // Display the tooltip below the parent element
                        left: "-5px", // Adjust the left position as needed
                        backgroundColor: "Grey",
                        color: "black",
                        fontWeight: 500,
                        padding: "5px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        opacity: 0.8,
                        width: "70px"
                      }}
                    >
                      Copied!
                    </span>
                  )}
                </span>
              </CopyToClipboard>
            </td>
              <td className="f-16 fw-400 tableText">Static</td>
              <td className="f-16 fw-400 tableText">Lorem ipsum dolor</td>
              <td className="f-16 fw-400 tableText">Chennai</td>
              <td className="f-16 fw-400 tableText">Facebook</td>
              <td className="f-16 fw-400 tableText">
                <small className="status-active">Active</small>
              </td>
              <td>
                <div className="mt-3">
                  <Actions />
                </div>
              </td>
            </tr>
        <tr>
          <td className="f-16 fw-500 tableText">Test Code1</td>
          <td className="f-16 fw-400 tableText">linkoo.web/34meiprs.com<LiaCopy /></td>
          <td className="f-16 fw-400 tableText">Dynamic</td>
          <td className="f-16 fw-400 tableText">sit amet, consectetur</td>
          <td className="f-16 fw-400 tableText">Salem</td>
          <td className="f-16 fw-400 tableText">Linked In</td>
          <td className="f-16 fw-400 tableText">
            <small className="status-inactive">Inactive</small>
          </td>
          <td>
            <div className="mt-3">
              <Actions />
            </div>
          </td>
        </tr>
        <tr>
          <td className="f-16 fw-500 tableText">Test Code2</td>
          <td className="f-16 fw-400 tableText">linkoo.web/34meiprs.com<LiaCopy /></td>
          <td className="f-16 fw-400 tableText">Static</td>
          <td className="f-16 fw-400 tableText">Lorem ipsum dolor</td>
          <td className="f-16 fw-400 tableText">Thanjavur</td>
          <td className="f-16 fw-400 tableText">Appointments</td>
          <td className="f-16 fw-400 tableText">
            <small className="status-active">Active</small>
          </td>
          <td>
            <div className="mt-3">
              <Actions />
            </div>
          </td>
        </tr>
      </tbody>
        </Table>
      </div>
    );
}
  