import { LocationSearching } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

const Location = ({ handleInputChange }) => {
  const [locations, setLocations] = useState([
    { address: "", cta_name: "" },
  ]);

  const handleChange = (index, field, value) => {
    const updatedLocations = [...locations];
    updatedLocations[index][field] = value;
    setLocations(updatedLocations);
  };

  const addLocation = () => {
    setLocations([...locations, { address: "", cta_name: "" }]);
  };

  const removeLocation = (index) => {
    const updatedLocations = locations.filter((_, i) => i !== index);
    setLocations(updatedLocations);
  };

  useEffect(() => {
    if (locations.every((location) => location.address && location.cta_name)) {
      const event = {
        target: {
          name: "location_address",
          value: locations,
        },
      };
      handleInputChange(event);
    }
  }, [LocationSearching]);

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}
    >
      <Form.Label className="form-label-h">Location Details</Form.Label>
      {locations.map((location, index) => (
        <div key={index} className="mb-4">
          <Row className="align-items-center">
            {/* Google Maps Link */}
            <Col md={6} className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter Google Maps Link"
                value={location.address}
                name="address"
                onChange={(e) =>
                  handleChange(index, "address", e.target.value)
                }
              />
            </Col>

            {/* CTA Name */}
            <Col md={4} className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter CTA Name"
                value={location.cta_name}
                name="cta_name"
                onChange={(e) =>
                  handleChange(index, "cta_name", e.target.value)
                }
              />
            </Col>

            {/* Remove Button */}
            <Col md={2}>
              {locations.length > 1 && (
                <Button
                  variant="danger"
                  onClick={() => removeLocation(index)}
                  size="sm"
                >
                  Remove
                </Button>
              )}
            </Col>
          </Row>
        </div>
      ))}

      {/* Add More Locations */}
      <Button variant="primary" onClick={addLocation} size="sm">
        Add Location
      </Button>

      {/* Display Locations in a Card */}
      {locations.every((location) => location.address && location.cta_name) && (
        <Card className="mt-4">
          <Card.Header>Added Locations</Card.Header>
          <Card.Body>
            {locations.map((location, index) => (
              <div key={index} className="mb-3">
                <Row>
                  <Col md={6}>
                    <strong>Google Maps Link:</strong> {location.address}
                  </Col>
                  <Col md={6}>
                    <strong>CTA Name:</strong> {location.cta_name}
                  </Col>
                </Row>
              </div>
            ))}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Location;
