import { createSlice } from "@reduxjs/toolkit";
import { ProfileDetails } from "../../async-actions/Merchant/profile";


const initialState = {
 profile: {},
  status: "",
};


const profileslice = createSlice({
  name: "profile",
  initialState,
//   extraReducers: {
//     [ProfileDetails.fulfilled]: (state, action) => {
//       state.profile = action.payload;
//     },
//   },
  extraReducers: (builder) => {
    builder.addMatcher(ProfileDetails.fulfilled.match, (state, action) => {
        state.profile = action.payload;
        console.log("data", action.payload)
    })
},
});

export default profileslice.reducer;
